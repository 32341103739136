export class UserTypeMapper{

    static typeMap = {
        noob: 'Çaylak',
        normal: 'Normal',
        vip: 'VIP',
        premium: 'PRM'
    }
    public static map(type: string){
       if(type in this.typeMap){
           return this.typeMap[type];
       }
       return type;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateLayoutComponent } from './private-layout/private-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [PrivateLayoutComponent, PublicLayoutComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule
  ], exports: [
    PrivateLayoutComponent, PublicLayoutComponent
  ], providers: [PrivateLayoutComponent]
})
export class LayoutModule { }


export enum ChatChannelEnum {
  JOIN = 'chatJoin',
  LEAVE = 'chatLeave',
  BAN = 'chatBan',
  DROP = 'chatDrop',
  MESSAGE = 'chatMessage',
  INVITE = 'privateChatInvite',
  INFO = 'chatInfo',
  PARTICIPANTS = 'chatParticipants',
  JOINED = 'chatJoined',
  MANAGE = 'chatManage',
  SENT = 'sentChatMessage',
  STORIES = 'stories'
}

export enum ChatInviteActionEnum {
  Request  = 'request',
  Accept = 'accept',
  Reject = 'reject',
}

export interface ChatInviteDataInterface {
  action: ChatInviteActionEnum;
  owner?: any;
  streamId?: string;
  userSocketId: string;
  createdAt?: number;
}

export enum ChatInfoActionEnum {
  StreamerLeft  = 'streamer-left',
  UserLeft = 'user-left',
  Over ='over',
  Banned = 'banned',
}

export interface ChatInfoDataInterface {
  action: ChatInfoActionEnum;
  message?: string;
}

export interface ChatMessagePaylaodInterface {
  roomId: string;
  message?: string;
  gift?: string;
  filter?: string;
}
export interface ChatParticipantInterface {
  owner: any;
  ownerType: 'User' | 'Streamer' | 'Manager' | 'Guest';
  socketId: string;
  channel: string;
  roomId: string;
  connected: boolean;
  excluded?: boolean;
  banned?: boolean;
  reason?: string;
}


export class PaymentTypesMapper {

  static typeMap = {
    card: 'Kredi Kartı',
    mobile: 'Mobil Ödeme',
    transfer: 'Havale',
    bonus: 'Bonus',
    credit: 'Kredi'
  }
  public static map(type: string) {
    if (type in this.typeMap) {
      return this.typeMap[type];
    }
    return type;
  }
}

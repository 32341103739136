import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getCampaign() {
    return this.http.get(this.apiUrl + "manager/campaign");
  }

  getActiveCampaign() {
    return this.http.get(this.apiUrl + "manager/campaign/active");
  }

  addCampaign(obj) {
    return this.http.post(this.apiUrl + "manager/campaign", obj);
  }

  deleteCampaign(campaignId) {
    return this.http.delete(`${this.apiUrl}manager/campaign/${campaignId}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { NoteService } from '../../services/note.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  notes;
  filterForm: FormGroup;
  constructor(private noteService: NoteService, private fb: FormBuilder) { }

  ngOnInit(): void {
    let d = new Date();
    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let dateStr = year + "/" + month + "/" + date;

    this.filterForm = this.fb.group({
      limit: [5]
    });
    this.getNotes();
  }

  getNotes() {
    this.noteService.getNotes(this.filterForm.value).subscribe(data => {
      this.notes = data;
    })
  }

}

<div class="hediye-gonder">
    <h3>HediyeGönder</h3>
    <form [formGroup]="sendGiftForm">
        <div class="autocomplete-ctn">
            <input class="hizli-user complete" (input)="onChange('userName')" type="text" placeholder="Kullanıcı adı"
                formControlName="userName">


            <ul *ngIf="giftUsers.length >= 1" class="member-list-hediye completeList" style="top: 50px;right: 11px;">
                <li *ngFor="let item of giftUsers;" (click)="setUser(item)"><a href="javascript:void;"><span
                            class="list-name">{{item?.username}}</span><span class="list-number">#{{item?.userId}}</span></a> </li>
            </ul>

        </div>
        <input class="hizli-mebla numberlimit2" type="text" placeholder="Kredi" formControlName="credit" maxlength="4"
            pattern="([0-9]|[0-9]|[0-9])">
        <div class="autocomplete-ctn">
            <input class="hizli-model complete" type="text" (input)="onChange('streamerName')" placeholder="Yayıncı adı"
                formControlName="streamerName">
            <ul *ngIf="streamers.length >= 1" class="member-list-hediye completeList" style="top: 50px;right: 11px;">
                <li *ngFor="let item of streamers;" (click)="setStreamer(item)"><a href="javascript:void;"><span
                            class="list-name">{{item?.streamerName}}</span><span class="list-number">#{{item?.streamerId}}</span></a> </li>
            </ul>
        </div>
        <input type="button" (click)="onSubmit()" value="" class="okbutton" />
    </form>
</div>

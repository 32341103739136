import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss']
})
export class PrivateLayoutComponent implements OnInit {

  className: string = 'modal-successful';
  modalMessage: string;
  constructor(private dataService: DataService, private spinner: NgxSpinnerService ) { }

  ngOnInit(): void {

    this.dataService.dataServiceItem.asObservable().subscribe(data => {
      if (data.type === 'successful') {
        this.className = 'modal-success';
        this.modalMessage = data?.message ?? 'İşlem başarıyla gerçekleştirildi.';
        this.openModal();
      } else if (data.type === 'error') {
        this.className = 'modal-fail';
        this.modalMessage = data?.message ?? 'Bir sorun ile karşılaşıldı, lütfen kontrol edip tekrar güncelleyiniz.';
        this.openModal();
      } else {
        this.modalMessage = '';
      }
    })

  }

  showSpinner() {
    this.spinner.show();
  }

  endSpinner() {
    this.spinner.hide();
  }

  modalStatus: boolean = false;
  isVisible() {
    return this.modalStatus;
  }

  closeModal() {
    this.modalStatus = !this.modalStatus;
  }


  openModal() {
    this.modalStatus = !this.modalStatus;
  }

}

<div id="container">
  <div id="top-bar">
    <p class="top-left">
      <strong>Cafecanli.com:</strong> 3.49 - 3.68 - 5.28 &nbsp;/&nbsp; <strong>Global.com:</strong> 3.53 - 2.43 - 5.23
    </p>
    <p class="top-right">
      <strong>{{ (auth$ | async)?.email }}</strong> <strong> IP:</strong> {{ (auth$ | async)?.lastLoginInfo?.ip }}
      <a class="logout" [routerLink]="['/auth/login']" (click)="logout()">Çıkış Yap</a>
    </p>
  </div>
  <nav id="main-menu">
    <a class="logo" [routerLink]="['/dashboard']"><img src="../../../../assets/img/logo.png" /></a>
    <ul class="top-menu">
      <li><a [routerLink]="['/members']">ÜYELER</a></li>
      <li>
        <a [routerLink]="['/streamer']">YAYINCILAR</a
        ><span class="menu-notice" *ngIf="waitinStreamer > 0">{{ waitinStreamer }}</span>
      </li>
      <li><a [routerLink]="['/agency']">AJANS</a></li>
      <li><a [routerLink]="['/business-partners']" href="javascript:void;">SATIŞ ORTAKLIĞI</a></li>
      <li><a [routerLink]="['/announcement']">DUYURU</a></li>
      <li>
        <a [routerLink]="['/messages']">MESAJLAR</a>
        <!-- <span class="menu-notice">78</span> -->
      </li>
      <li>
        <a [routerLink]="['/photos']">FOTOĞRAF</a
        ><span class="menu-notice" *ngIf="unApprovedPhoto > 0">{{ unApprovedPhoto }}</span>
      </li>
      <li *hasPermission="['payments-view']">
        <a [routerLink]="['/payments']">ÖDEMELER</a
        ><span class="menu-notice" *ngIf="waitingPayment > 0"> {{ waitingPayment }}</span>
      </li>
    </ul>
    <div class="user-search">
      <div class="member-search">
        <form [formGroup]="headerForm">
          <input
            type="radio"
            formControlName="membersearch"
            (change)="onChange($event)"
            value="nick"
            id="nick1"
          /><label for="nick1"> Nick &nbsp;|&nbsp;</label>
          <input
            type="radio"
            formControlName="membersearch"
            (change)="onChange($event)"
            value="eposta"
            id="eposta1"
          /><label for="eposta1"> E-posta</label>
          <div class="autocomplete">
            <input
              autoComplete="off"
              (ngModelChange)="change('userName')"
              formControlName="userName"
              id="member-nicksearch"
              class="nicksearch complete"
              type="text"
              name="nicksearch"
              placeholder="Hızlı üye ara"
              autofocus
            />
            <ul *ngIf="users.length >= 1" class="member-list-hediye completeList">
              <li *ngFor="let item of users" (click)="setUser(item)">
                <a href="javascript:void;"
                  ><span class="list-name">{{ item.username }}</span
                  ><span class="list-number">#{{ item.userId }}</span></a
                >
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="member-search">
        <form [formGroup]="headerForm">
          <input type="radio" formControlName="modelsearch" value="nick" id="nick2" (change)="onChangeModal($event)" />
          <label for="nick2"> Nick &nbsp;|&nbsp;</label>
          <input type="radio" formControlName="modelsearch" value="isim" id="isim1" (change)="onChangeModal($event)" />
          <label for="isim1"> İsim</label>
          <div class="autocomplete">
            <input
              autoComplete="off"
              (ngModelChange)="change('streamer')"
              formControlName="streamerName"
              id="model-nicksearch"
              class="nicksearch complete"
              type="text"
              name="nicksearch"
              placeholder="Hızlı yayıncı ara"
              autofocus
            />
            <ul *ngIf="streamer.length >= 1" class="member-list-hediye completeList">
              <li *ngFor="let item of streamer" (click)="setStreamer(item)">
                <a href="javascript:void;"
                  ><span class="list-name">{{ item.streamerName }}-{{ item.username }}</span
                  ><span class="list-number">#{{ item.streamerId }}</span></a
                >
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </nav>
  <div class="left-ear"></div>
  <div class="top-sep"></div>
  <div class="right-ear"></div>
</div>

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {

  apiUrl = environment.prodUrl;
  constructor(private http: HttpClient) { }

  getNotice(ownerId, limit?) {
    let params = `owner=${ownerId}`;
    if (limit) {
      params += `&limit=${limit}`;
    }
    return this.http.get(`${this.apiUrl}manager/notice?${params}&sort=createdAt:desc`);
  }

  postNotice(obj) {
    return this.http.post(`${this.apiUrl}manager/notice`, obj);
  }

  deleteNotice(noticeId) {
    return this.http.delete(`${this.apiUrl}manager/notice/${noticeId}`);
  }
}

<div>
  <h4 class="warning-title">CEZA VER</h4>
  <p class="warning-content">
    {{owner?.name}} kullanıcısına vermek istediğiniz cezayı seçiniz.
  </p>
  <div class="credit-check2 multi-check">
    <label class="container">1 Saat
      <input type="radio" name="filter-table-1" [value]="1" [(ngModel)]="penaltyDuration">
      <span class="checkmark"></span>
    </label>
    <label class="container">3 Saat
      <input type="radio" name="filter-table-1" [value]="3"  [(ngModel)]="penaltyDuration">
      <span class="checkmark"></span>
    </label>
    <label class="container">12 Saat
      <input type="radio" name="filter-table-1" [value]="12"  [(ngModel)]="penaltyDuration">
      <span class="checkmark"></span>
    </label>
    <label class="container">1 Gün
      <input type="radio" name="filter-table-1" [value]="24"  [(ngModel)]="penaltyDuration">
      <span class="checkmark"></span>
    </label>
  </div>
  <button class="msgbutton f12 mr10 ml100 modal-warning-toggle3" (click)="submit()">Onayla</button>
  <button class="cancelbutton f12 modal-warning-toggle3" (click)="cancel()">Vazgeç</button>
</div>
import { UserTypeEnum } from './../utils/user-type.enum';
import { Injectable } from '@angular/core';
import  { Janus }  from "janus-gateway";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { v4 as uuid } from 'uuid';

export interface JoinOptions {
  roomToken?: string,
  room: string,
  display: string,
  id: string;
  feedId?: string;
}

export interface PublishOptions {
  audioDeviceId: string;
  videoDeviceId: string;
}

export interface Publisher {
  audio_codec: string;
  display: string;
  id: number;
  talking: boolean;
  video_codec: string;
}

export interface CreateStreamPayload {
  type: 'public' | 'private';
  user?: string;
  streamerFilter?:string;
  disablePrivate?: boolean;
}

export interface JoinStreamPayload {
  type: 'public' | 'private';
  username: string;
  streamId: string;
}

export interface StreamInterface {
  type: 'public' | 'private',
  id: string,
  accessToken?: string,
  streamer?: StreamStreamerInterface;
  user?: StreamUserInterface;
  disablePrivate?: boolean;
  streamerFilter?: string;
}

export interface StreamStreamerInterface {
  id: string;
  username: string;
  streamerName?: string;
  followCount: number;
  followerCount: number;
  creditPerMinute: number;
  profile: string;
  isFollowing?: boolean;
}

export interface StreamUserInterface {
  id: string;
  name: string;
  username:  string;
  profile: string;
  isFollowing: boolean
  type: UserTypeEnum;
}

export enum JanusEventTypeEnum {
  Joined,
  Leaved,
  PublisherLeaving,
  PublisherJoined,
  LocalStream,
  RemoteStream,
  RemoteStreamCleanUp,
}

export interface JanusEventInterface {
  type: JanusEventTypeEnum;
}

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  public events: Observable<any>
  private _janus: Janus;
  private _handle: any;
  private _feeds: any[] = [];
  private _apiUrl = environment.prodUrl;
  private _eventSubject: Subject<any>;
  private _currentJoinOptions: JoinOptions;
  private _initialized: boolean = false;
  constructor(
    private _http: HttpClient,
  ){
    this._eventSubject = new Subject();
    this.events = this._eventSubject.asObservable();
  }

  public async init(listDevices: boolean = false){
    if(this._initialized){
      return new Promise((resolve, reject) => {
        if(listDevices){
          Janus.listDevices((devices) => {
            resolve(devices);
          });
        }else {
          resolve(null);
        }
      })
    }

    return new Promise((resolve, reject)=> {
      Janus.init({
        debug: "error",
        callback: ()=> {
          this._initialized =  true;
          if(!Janus.isGetUserMediaAvailable()){
            reject()
          }else if(listDevices){
            Janus.listDevices((devices) => {
              resolve(devices);
            });
          }else {
            resolve(null);
          }
        }
      })
    })
  }

  public async getDevices(){
    return new Promise((resolve, reject) => {
      if(!Janus.isGetUserMediaAvailable()){
        reject()
      }else{
        Janus.listDevices((devices) => {
          resolve(devices);
        });
      }
    })
  }

  public async destroy(){
    if(this._janus){
      this._janus.destroy({ unload: true});
      this._janus = undefined;
    }
  }

  public async hangup(){
    // console.log("###HANGUP", this._handle, this._feeds);
    if(this._handle) {
      this.leave();
      this._handle.hangup();
      this._handle.detach();
    }
    if(this._feeds.length>0){
      this._feeds.forEach(f => f.detach());
    }
    this._handle = undefined;
  }

  public async attach(opaqueId: string, janusToken: string):  Promise<any>{
    console.log("ATTACHING");
    if(!this._janus || !this._janus.isConnected()){
      this._janus = await this._connect(janusToken);
    }

    this._handle = await new Promise((resolve, reject) => {
      this._janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId,
          success: (pluginHandle: any) =>{
            pluginHandle.simulcastStarted = false;
            resolve(pluginHandle);
          },
          error: (error) => {
            reject(error);
          },
          mediaState: (medium, on)=> {
            console.log("MediaState:", medium, on);
          },
          webrtcState: (on) => {
            console.log("WebRTC State:", on);
          },
          onmessage: (message, jsep) => {
            if(message?.videoroom === 'joined'){
              this._eventSubject.next({ type:  JanusEventTypeEnum.Joined });
            }
            if(message?.videoroom === 'participants' && message?.participants?.length>0){
              // this._store.dispatch(new SetParticipants(message?.participants?.map(p=> p.id)));
            }
            if(message?.publishers && message?.publishers?.length > 0){
              Promise.all(message?.publishers?.map(p => this._subscribe(p))).then(feeds => this._feeds.push(...feeds));
            }
            if(message?.leaving){
              const leavingFeed = this._feeds.find(feed => feed.rfid === message?.leaving);
              if(leavingFeed){
                this._eventSubject.next({ type:  JanusEventTypeEnum.PublisherLeaving });
                leavingFeed.detach();
                this._feeds = this._feeds.filter(f => f!== leavingFeed);
              }
            }
            if(message?.unpublished){
              if(message?.unpublished === 'ok') {
                //That's us
                this._handle.hangup();
              }
              const unpublishedFeed = this._feeds.find(feed => feed.rfid === message?.unpublished);
              if(unpublishedFeed){
                unpublishedFeed.detach();
              }
            }
            // if (message["publishers"] !== undefined && message["publishers"] !== null) {
            //   var list = message["publishers"];
            //   Janus.debug("Got a list of available publishers/feeds:");
            //   Janus.debug(list);
            //   for (var f in list) {
            //     var id = list[f]["id"];
            //     var display = list[f]["display"];
            //     var audio = list[f]["audio_codec"];
            //     var video = list[f]["video_codec"];
            //     Janus.debug("  >> [" + id + "] " + display + " (audio: " + audio + ", video: " + video + ")");
            //     // newRemoteFeed(id, display, audio, video);
            //   }
            // }
            console.log("On Message:", message, jsep);
            if(jsep !== undefined && jsep !== null) {
              this._handle.handleRemoteJsep({ jsep });
              // if(options.type == 'publisher'){
              //   this._handle.handleRemoteJsep({ jsep });
              // }else {
              //   this._handle.createAnswer(
              //     {
              //       jsep: jsep,
              //       // Add data:true here if you want to subscribe to datachannels as well
              //       // (obviously only works if the publisher offered them in the first place)
              //       media: {
              //         audioSend: false,
              //         videoSend: false,
              //         data: false,
              //       }, // We want recvonly audio/video
              //       success: (jsep) => {
              //         Janus.debug("Got SDP!");
              //         Janus.debug(jsep);
              //         var body = {
              //           request: "start",
              //           room: options.room,
              //           token: options.token,

              //         };
              //         this._handle.send({
              //           "message": body,
              //           "jsep": jsep
              //         });
              //       },
              //       error: function(error) {
              //         Janus.error("WebRTC error:", error);
              //       }
              //     });
              // }

            }
          },
          onlocalstream: (stream) => {
            this._eventSubject.next({ type: JanusEventTypeEnum.LocalStream, stream });
          },
          onremotestream: (stream) => {
            var videoTracks = stream.getVideoTracks();
            console.log("Video Tracks", stream);
            this._eventSubject.next({ type: JanusEventTypeEnum.RemoteStream, stream });
          },
          oncleanup: () => {
            console.log("On clean up");
          },
        }
      );
    });
    console.log("ATTACHED,", this._handle);
  }

  public async join(options: JoinOptions){
    this._currentJoinOptions = options;
    const message = {
      request: 'join',
      room: options.room,
      ptype: 'publisher',
      id: options.id+'/'+options.room,
      display: options.display,
      token: options.roomToken
    };
    this._handle.send({ message });
  }

  public async leave(){
    this._handle.send({ request: 'leave' });
  }

  public kick(id: string){
    if(this._handle){
      this._handle.send({"message": {
        "request": "kick",
        "room": this._currentJoinOptions.room,
        "secret": this._currentJoinOptions.roomToken,
        "id": id
      }});
    }
  }

  public toggleMic(enabled?: boolean){
    if(this._handle){
      this._handle.send({"message": { "request": "configure", "audio": enabled }});
    }
  }
  public toggleAudio(): boolean {
    if(this._handle){
      const muted = this._handle.isAudioMuted();
      if(muted){
        this._handle.unmuteAudio();
      }else {
        this._handle.muteAudio();
      }
      return this._handle.isAudioMuted()
    }
    return false;
  }

  public configureBitrate(rate) {
    if(this._handle){
      this._handle.send({"message": { "request": "configure", "bitrate": rate*1000 }});
    }
    this._feeds.forEach(f => {
      f.send({"message": { "request": "configure", "bitrate": rate*1000 }});
    })
  }

  public async setSubstream(substream){
    this._feeds.forEach(f => {
      f.send({"message": { "request": "configure", "substream": substream }});
    })
  }

  public async getParticipants(room: number){
    return this._handle.send({"message": {
      "request" : "listparticipants",
      "room" : room
    }})
  }

  public async publish(options: PublishOptions){
    console.log("PUBBBB");
    const doSimulcast = true;
    this._handle.createOffer(
      {
        // Add data:true here if you want to publish datachannels as well
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: true,
          videoSend: true,
          // video: "hires",
          // audio: true,
          // video: true,
          audio: {
            deviceId: {
              exact: options.audioDeviceId,
            }
          },
          video: {
            deviceId: {
              exact: options.videoDeviceId,
            }
          },
        },	// Publishers are sendonly
        // If you want to test simulcasting (Chrome and Firefox only), then
        // pass a ?simulcast=true when opening this demo page: it will turn
        // the following 'simulcast' property to pass to janus.js to true
        simulcast: doSimulcast,
        success: (jsep) => {
          Janus.debug("Got publisher SDP!");
          Janus.debug(jsep);
          var publish = {
            request: 'configure',
            audio: true,
            video: true,
            bitrate: 3000000,
          };
          // You can force a specific codec to use when publishing by using the
          // audiocodec and videocodec properties, for instance:
          // 		publish["audiocodec"] = "opus"
          // to force Opus as the audio codec to use, or:
          // 		publish["videocodec"] = "vp9"
          // to force VP9 as the videocodec to use. In both case, though, forcing
          // a codec will only work if: (1) the codec is actually in the SDP (and
          // so the browser supports it), and (2) the codec is in the list of
          // allowed codecs in a room. With respect to the point (2) above,
          // refer to the text in janus.plugin.videoroom.cfg for more details
          this._handle.send({message: publish, jsep});
        },
        error: (error) => {
          Janus.error("WebRTC error:", error);
          // if (useAudio) {
          //    publishOwnFeed(false);
          // } else {
          //   bootbox.alert("WebRTC error... " + JSON.stringify(error));
          //   $('#publish').removeAttr('disabled').click(function() { publishOwnFeed(true); });
          // }
        }
      })
  }

  public unpublish(){
    this._handle.send({ message: {
       request: "unpublish"
    }})
  }

  public create(payload: CreateStreamPayload): Observable<StreamInterface>{
    return this._http.post<any>(this._apiUrl + 'stream', payload)
      .pipe(
        map((response: any) => ({
          type: response?.type,
          id: response?._id,
        }))
      )
  }

  public getStream(streamId: string): Observable<StreamInterface>{
    return this._http.get<StreamInterface>(this._apiUrl + `stream/${streamId}`, {});
  }

  public getRoom(room: string): Observable<any>{
    return this._http.get<any>(this._apiUrl + `stream/room/${room}`, {});
  }

  public getToken(): Observable<{ token: string }>{
    return this._http.get<{ token: string }>(this._apiUrl + 'stream/token');
  }

  public setPublicRoomPublisherId(id: number, room: number): Observable<any>{
    return this._http.put<any>(this._apiUrl + `stream/${room}/publisher-id/${id}`,{});
  }

  public getBitrates(){
    if(this._feeds?.length  > 0 ){
      return this._feeds[0].getBitrate();
    }
    return -1;
  }

  public async joinAsSubscriber(options: JoinOptions){
    const feedHandle: any = await new Promise((resolve, reject) => {
      this._janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: options.id,
          success: (pluginHandle: any) =>{
            pluginHandle.simulcastStarted = false;
            const message = {
              request: 'join',
              room: options.room,
              ptype: 'subscriber',
              feed: options.feedId+'/'+options.room,
              id: options.id,
              display: options.display,
              token: options.roomToken
            };
            pluginHandle.send({ message });
            resolve(pluginHandle);
          },
          error: (error) => {
            reject(error);
          },
          mediaState: (medium, on)=> {
            console.log("MediaState:", medium, on);
          },
          webrtcState: (on) => {
            console.log("WebRTC State:", on);
          },
          onmessage: (message, jsep) => {
            if(message?.videoroom === 'attached'){
              this._eventSubject.next({type: JanusEventTypeEnum.PublisherJoined })
              // this._store.dispatch(new PublisherJoined())
            }
            if(message?.videoroom  === "event") {
              // Check if we got an event on a simulcast-related event from this publisher
              if(message?.substream || message?.temporal) {
                if(!feedHandle.simulcastStarted) {
                  feedHandle.simulcastStarted = true;
                  // Add some new buttons
                  // addSimulcastButtons(remoteFeed.rfindex, remoteFeed.videoCodec === "vp8");
                }
                // We just received notice that there's been a switch, update the buttons
                // updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
              }
            }

            console.log("On Message:", message, jsep);
            if(jsep !== undefined && jsep !== null) {

              feedHandle.createAnswer(
                {
                  jsep: jsep,
                  // Add data:true here if you want to subscribe to datachannels as well
                  // (obviously only works if the publisher offered them in the first place)
                  media: {
                    audioSend: false,
                    videoSend: false,
                    data: false,
                  }, // We want recvonly audio/video
                  success: (jsep) => {
                    Janus.debug("Got SDP!");
                    Janus.debug(jsep);
                    var body = {
                      request: "start",
                      room: options.room,
                    };
                    feedHandle.send({
                      "message": body,
                      "jsep": jsep
                    });
                  },
                  error: function(error) {
                    Janus.error("WebRTC error:", error);
                  }
                });

            }
          },
          onlocalstream: (stream) => {
            this._eventSubject.next({ type: JanusEventTypeEnum.LocalStream, stream});
          },
          onremotestream: (stream) => {
            var videoTracks = stream.getVideoTracks();
            console.log("Video Tracks", stream);
            this._eventSubject.next({ type: JanusEventTypeEnum.RemoteStream, stream });
          },
          oncleanup: () => {
            console.log("On clean up");
          },
        }
      );
    })
    feedHandle.rfid = options.feedId;
    return feedHandle;
  }

  private async _subscribe(publisher: Publisher): Promise<any>{
    const feedHandle: any = await new Promise((resolve, reject) => {
      this._janus.attach(
        {
          plugin: "janus.plugin.videoroom",
          opaqueId: this._currentJoinOptions.id,
          success: (pluginHandle: any) =>{
            pluginHandle.simulcastStarted = false;
            const message = {
              request: 'join',
              room: this._currentJoinOptions.room,
              ptype: 'subscriber',
              feed: publisher.id,
              id: this._currentJoinOptions.id,
              display: this._currentJoinOptions.display,
              token: this._currentJoinOptions.roomToken
            };
            pluginHandle.send({ message });
            resolve(pluginHandle);
          },
          error: (error) => {
            reject(error);
          },
          mediaState: (medium, on)=> {
            console.log("MediaState:", medium, on);
          },
          webrtcState: (on) => {
            console.log("WebRTC State:", on);
          },
          onmessage: (message, jsep) => {
            if(message?.videoroom === 'attached'){
              this._eventSubject.next({type: JanusEventTypeEnum.PublisherJoined })
              // this._store.dispatch(new PublisherJoined())
            }
            if(message?.videoroom  === "event") {
              // Check if we got an event on a simulcast-related event from this publisher
              if(message?.substream || message?.temporal) {
                if(!feedHandle.simulcastStarted) {
                  feedHandle.simulcastStarted = true;
                  // Add some new buttons
                  // addSimulcastButtons(remoteFeed.rfindex, remoteFeed.videoCodec === "vp8");
                }
                // We just received notice that there's been a switch, update the buttons
                // updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
              }
            }

            console.log("On Message:", message, jsep);
            if(jsep !== undefined && jsep !== null) {

              feedHandle.createAnswer(
                {
                  jsep: jsep,
                  // Add data:true here if you want to subscribe to datachannels as well
                  // (obviously only works if the publisher offered them in the first place)
                  media: {
                    audioSend: false,
                    videoSend: false,
                    data: false,
                  }, // We want recvonly audio/video
                  success: (jsep) => {
                    Janus.debug("Got SDP!");
                    Janus.debug(jsep);
                    var body = {
                      request: "start",
                      room: this._currentJoinOptions.room,
                    };
                    feedHandle.send({
                      "message": body,
                      "jsep": jsep
                    });
                  },
                  error: function(error) {
                    Janus.error("WebRTC error:", error);
                  }
                });

            }
          },
          onlocalstream: (stream) => {
            this._eventSubject.next({ type: JanusEventTypeEnum.LocalStream, stream});
          },
          onremotestream: (stream) => {
            this._eventSubject.next({ type: JanusEventTypeEnum.RemoteStream, stream, publisher });
          },
          oncleanup: () => {
            console.log("On clean up");
            this._eventSubject.next({ type: JanusEventTypeEnum.RemoteStreamCleanUp, publisher });
          },
        }
      );
    })
    feedHandle.rfid = publisher.id;
    return feedHandle;
  }

  private async _connect(token: string){
    console.log("###CONNEEEECT");
    return new Promise((resolve, reject) => {
      const janus = new Janus({
        server: environment.janusUrl,
        token,
        success: ()=> {
          resolve(janus);
        },
        error: (error: any)=> {
          reject(error)
        }
      });
    })
  }
  dropStream(streamId: String) {
    return this._http.delete(`${this._apiUrl}manager/stream/${streamId}`)
  }

}

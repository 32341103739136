import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuickSearchService {
  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  quickSearch(username: string, type: string, searchArea: string) {
    let t;
    if (type === 'eposta') {
      t = 'email&email';
    } else {
      t = 'username&username';
    }
    return this.http.get(this.apiUrl + `manager/${searchArea}/quickSearch?field=${t}=` + username);
  }

  streamerQuickSearch(search: string) {
    return this.http.get(this.apiUrl + 'manager/streamer/quickSearch', {
      params: {
        search,
      },
    });
  }


  getGateways() {
    return this.http.get(this.apiUrl + 'manager/money/gateways');
  }


}

import { SourceMapper } from './../../../shared/helper/source-mapper';
import { PrivateStreamComponent } from './../../../shared/components/stream/private-stream/private-stream.component';
import { PublicStreamComponent } from './../../../shared/components/stream/public-stream/public-stream.component';
import { environment } from 'src/environments/environment';
import { StreamerStateModel } from './../../../shared/store/_model/streamer.state.model';
import { Observable } from 'rxjs';
import { StreamInfo, StreamState } from './../../../shared/store/_state/stream.state';
import { Select, Store } from '@ngxs/store';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MembersService } from 'src/app/shared/services/members.service';
import { DataService } from 'src/app/shared/services/data.service';
import { PaymentTypesMapper } from 'src/app/shared/utils/payment-type-mapper';
import { UserTypeMapper } from 'src/app/shared/utils/user-type.mapper';
import { NumberFormatMapper } from 'src/app/shared/utils/number-format-mapper';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Navigate } from '@ngxs/router-plugin';
import { AppType } from '../../../shared/utils/app-type.util';
import { GiftFromSpending, PaginatedAPIResponse } from '../../../types';
import * as dayjs from 'dayjs';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @Select(StreamState.publicStreamers) publicStreamers$: Observable<StreamInfo[]>;
  @Select(StreamState.privateStreamers) privateStreamers$: Observable<StreamInfo[]>;
  @Select(StreamState.fakeStreamers) fakeStreamers$: Observable<StreamInfo[]>;

  salesOfTodayColumns = [];

  registeredOfTodayColumns = [];

  salesOfTodayRows;
  registeredOfTodayRows;
  todaySentGifts: GiftFromSpending[];
  appType = AppType;

  constructor(
    private dataService: DataService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private memberService: MembersService,
    private ngxSmartModalService: NgxSmartModalService,
    private store: Store,
  ) {}

  filterForm: FormGroup;
  public static getMinAndMaxCreatedAt(date: Date): { minCreatedAt: string; maxCreatedAt: string } {
    const selectedDate = dayjs(date);
    const minCreatedAt = selectedDate.format('YYYY-MM-DD');
    const maxCreatedAt = selectedDate.add(1, 'day').format('YYYY-MM-DD');
    return {
      minCreatedAt,
      maxCreatedAt,
    };
  }
  ngOnInit(): void {
    this.loadjQuery();
    let today = new Date();

    const { minCreatedAt, maxCreatedAt } = DashboardComponent.getMinAndMaxCreatedAt(today);

    this.filterForm = this.fb.group({
      maxCreatedAt: [maxCreatedAt],
      sort: ['createdAt:desc'],
      minCreatedAt: [minCreatedAt],
      limit: [50],
    });

    this.dataService.dataServiceItem.asObservable().subscribe((data) => {
      if (data.type === 'credit') {
        this.getTodaySales();
      } else if (data.type === 'gift') {
        this.getTodaySentGifts();
      }
    });

    this.getTodaySales();
    this.getTodayRegistered();
    this.getTodaySentGifts();

    setInterval(() => {
      this.getTodaySales();
      this.getTodaySentGifts();
    }, 30000);
  }

  ngAfterViewInit() {}

  getTodaySales() {
    let params = this.filterForm.value;
    params = { ...params, limit: 50 };
    this.dashboardService.getSalesOfToday(params).subscribe((data) => {
      this.salesOfTodayRows = data['docs'];
      // console.log('salesOfTodayRows', this.salesOfTodayRows)
    });
  }

  getPaymentType(type, gateway?, managerName?) {
    type = PaymentTypesMapper.map(type);
    if (type === 'Mobil Ödeme') {
      return `${type} - ${(managerName || 'Sistem')}`;
    } else {
      return `${type} - ${gateway.name} - ${(managerName || 'Sistem')}`;
    }
  }

  getReferers(registerRef, lastLoginRef) {
      return `${registerRef || 'Adres Bar'} / ${lastLoginRef || 'Adres Bar'}`;
  }

  getUserType(type) {
    if (type === 'vip' || type === 'premium') {
      return UserTypeMapper.map(type);
    }
    return 'Kr';
  }

  getTodayRegistered() {
    this.memberService.getUsers(this.filterForm.value).subscribe((data) => {
      this.registeredOfTodayRows = data['docs'];
      console.log('registeredOfTodayRows', this.registeredOfTodayRows)
    });
  }

  getTodaySentGifts() {
    this.dashboardService
      .getTodaySentGiftsFromSpendings(this.filterForm.value)
      .subscribe((data: PaginatedAPIResponse<GiftFromSpending>) => {
        this.todaySentGifts = data.docs;
      });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  parseReferrer(ref) {

    if (!ref) {
      return "Adres Bar";
    } else if(ref == 'null' || ref == 'google-play') {
      return "Google-Play"
    } else {
      return ref;
    }
  }

  getFormattedNumber(number) {
    return NumberFormatMapper.format(number);
  }

  mouseOver = false;
  index;

  over(event, i) {
    this.index = i;
    this.mouseOver = true;
  }

  fire(event, i) {
    this.index = i;
    this.mouseOver = false;
  }

  loadjQuery() {
    $(document).ready(function () {
      $('.updown').on('click', function () {
        if ($('.big-table-container').height() == 308) {
          $('.big-table-container').animate({ height: '154px' });
          $('.updown').removeClass('up').addClass('down');
        } else if ($('.big-table-container').height() == 154) {
          $('.big-table-container').animate({ height: '308px' });
          $('.updown').removeClass('down').addClass('up');
        }
      });
    });
  }

  bgProfilePhoto(path) {
    if (typeof path === 'undefined' || path === null) {
      return "url('./assets/img/Streamer.png')";
    }
    return `url('${path}')`;
  }

  openPublicStream(streamer: StreamerStateModel) {
    console.log('Opening public stream');
    const modal = this.ngxSmartModalService.create(`streamModalPublic`, PublicStreamComponent, {
      closable: false,
      customClass: 'stream-dialog nsm-centered',
    });
    modal.removeData();
    modal.setData(streamer);
    modal.open();
  }

  openPrivateStream(streamer: StreamerStateModel) {
    const modal = this.ngxSmartModalService.create(`streamModalPrivate`, PrivateStreamComponent, {
      closable: false,
      customClass: 'stream-dialog nsm-centered',
    });
    modal.removeData();
    modal.setData(streamer);
    modal.open();
  }

  getSource(source: string) {
    return SourceMapper.map(source);
  }

  getParticipants(stream: StreamInfo) {
    return stream?.participants?.filter((p) => p.ownerType === 'User' || p.ownerType === 'Guest');
  }

  getManagerParticipants(stream: StreamInfo) {
    return stream?.participants
      ?.filter((p) => p.ownerType === 'Manager')
      ?.map((p) => p?.owner?.username)
      ?.join(' - ');
  }

  getParticipantCounts(stream: StreamInfo) {
    const participants = this.getParticipants(stream);
    const user = participants.filter((p) => p?.ownerType.toLowerCase() !== 'guest')?.length ?? 0
    const guest = participants.filter((p) => p?.ownerType.toLowerCase() === 'guest')?.length ?? 0;
    const total = participants?.length ?? 0;
    return { total, user, guest };
  }

  goToMember(id: string) {
    this.store.dispatch(new Navigate(['members', id]));
  }
}

export class MonthMapper {
  public static readonly months = {
    January: 'Ocak', July: 'Temmuz',
    February: 'Şubat', August: 'Ağustos',
    March: 'Mart', September: 'Eylül',
    April: 'Nisan', October: 'Ekim',
    May: 'Mayıs', November: 'Kasım',
    June: 'Haziran', December: 'Aralık',
  }

  private static readonly monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  static map(monthNumber) {
    let month = this.monthNames[monthNumber]
    if (month in this.months) {
      return this.months[month];
    }
  }
}

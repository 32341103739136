import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'diffInMin' })
export class DiffInMinPipe implements PipeTransform {


  transform(startAt: string): any {
    return  Math.floor(Math.abs((new Date().getTime() - new Date(startAt).getTime()) / 1000))
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  apiUrl = environment.prodUrl;
  
  constructor(private http: HttpClient) { }
  getUnApprovedPicForStreamer(obj){
    
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/streamer/waiting-approve-picture?${params}`);
  }

  getUnApprovedPicForUser(obj){
    
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/user/waiting-approve-picture?${params}`);
  }
  
}

<div class="stats">
  <div class="stats-head">
    <div class="stats-left">
      <div class="time">{{time | date:"HH:mm"}}</div>
      <div class="date">{{getTime(time)}}</div>
    </div>
    <div class="stats-right" *ngIf="homeStats">
      <div class="member-count">
        <span class="publics">{{(publicStreamers$ | async).length}}</span>
        /
        <span class="privates">{{(privateStreamers$ | async).length}}</span>
      </div>
      <div class="active-count">{{(onlineUserList$ | async).size}} Aktif Üye</div>
    </div>
  </div>
  <div class="site-stats" *ngIf="homeStats">
    <div class="stats-cafe">
      <p class="site-name">CafeCanli.com</p>
      <p class="stats-detail">
        <span class="site-stats-left">Ciro</span>:
        <span class="site-stats-right">
          {{formatNumber(homeStats.netEndorsementLocal)}} <span class="small">TL</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Dün</span>:
        <span class="site-stats-right">
          {{formatNumber(homeStats.netEndorsementLocalYesterday)}} <span class="small">TL</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Kayıt</span>:
        <span class="site-stats-right">
          {{homeStats.registeredMemberLocal}} <span class="small">Üye</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Satış</span>:
        <span class="site-stats-right">
          {{homeStats.purchaseCountLocal}} <span class="small">Adet</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Bonus</span>:
        <span class="site-stats-right">
          {{homeStats.givenBonusCountLocal}} <span class="small">Adet</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Genel</span>:
        <span class="site-stats-right">
          {{ fromMillisToHours(homeStats.GeneralRoomTimeLocal) }} <span class="small">Saat</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">BireBir</span>:
        <span class="site-stats-right">
          {{ fromMillisToHours(homeStats.oneToOneTimeLocal) }} <span class="small">Saat</span>
        </span>
      </p>
    </div>
    <div class="stats-cafe global">
      <p class="site-name">GlobalSite.com</p>
      <p class="stats-detail">
        <span class="site-stats-left">Ciro</span>:
        <span class="site-stats-right">
          {{formatNumber(homeStats.netEndorsementGlobal)}} <span class="small">USD</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Dün</span>:
        <span class="site-stats-right">
          {{formatNumber(homeStats.netEndorsementGlobalYesterday)}} <span class="small">USD</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Kayıt</span>:
        <span class="site-stats-right">
          {{homeStats.registeredMemberGlobal}} <span class="small">Üye</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Satış</span>:
        <span class="site-stats-right">
          {{homeStats.purchaseCountGlobal}} <span class="small">Adet</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Bonus</span>:
        <span class="site-stats-right">
          {{homeStats.givenBonusCountGlobal}} <span class="small">Adet</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">Genel</span>:
        <span class="site-stats-right">
          {{fromMillisToHours(homeStats.GeneralRoomTimeGlobal)}} <span class="small">Saat</span>
        </span>
      </p>
      <p class="stats-detail">
        <span class="site-stats-left">BireBir</span>:
        <span class="site-stats-right">
          {{fromMillisToHours(homeStats.oneToOneTimeGlobal)}}<span class="small">Saat</span>
        </span>
      </p>
    </div>
    <p class="all-stats"><a [routerLink]="['/reports/today-credits']">Tüm istatistikleri gör</a></p>
    <div class="kampanyalar">
      <h4>Kampanyalar</h4>
      <div id="progressBar" class="" *ngIf="activeCampaign?.active">
        <p class="progress-info"><span
          class="kampanya-bilgisi">{{activeCampaign?.credit?.credit}} Kr.</span><span>|</span><span
          class="kampanya-sure">{{activeCampaign?.bonus}} Kr.
                        </span><span>|</span><span class="time-left">Kalan
                        süre:</span><span class="countdown">{{activeCampaign.duration}}</span></p>
        <div class="bar"></div>
      </div>
      <p class="kampanya-buton">
        <a class="add-campaign"
           (click)="changeModalStatus()"
           style="margin-right: 5px;cursor: pointer;"
        >
          Kampanya ekle
        </a>
        <span *ngIf="activeCampaign?.active">/</span>
        <a *ngIf="activeCampaign?.active"
           (click)="cancelCampaign(activeCampaign._id)"
           style="margin-left: 5px;cursor: pointer;"
        >
          Kampanyayı iptal et
        </a>
      </p>
    </div>
    <p class="active-admin">
      <span>Aktif:</span>
      <ng-container *ngFor="let manager of managers;let last = last">
        {{manager}}
        <ng-container *ngIf="!last">-</ng-container>
      </ng-container>
    </p>
    <p class="kampanya-buton">
      <a [routerLink]="['/managers/system-settings']">Sistem Ayarları</a>
      <span> / </span>
      <a [routerLink]="['/managers']">Kullanıcılar</a>
      <span> / </span>
      <a [routerLink]="['/logs']">Loglar</a>
      <span> / </span>
      <a [routerLink]="['/reports']">Raporlar</a>
    </p>
  </div>
  <div class="clr"></div>
</div>

<div class="modal">
  <div class="modal-overlay modal-toggle"></div>
  <div class="modal-wrapper modal-transition">
    <div class="modal-header modal-success">
      <!-- hata durumunda modal-fail classı gelecek -->
      <button class="modal-close modal-toggle">
        <svg class="icon-close icon" viewBox="0 0 32 32">
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
      <h2 class="modal-heading">Başarılı işlem</h2>
    </div>

    <div class="modal-body">
      <div class="modal-content">
        <p><span>whitefox</span> nickli kullanıcıya <span>40 Kr.</span> bonus yüklemesi
          gerçekleştirildi.</p>
        <button class="modal-toggle tamam">Tamam</button>
      </div>
    </div>
  </div>
</div>


<div class="modal-campaign" [ngClass]="{ ' is-visible': isVisible()}">
  <div class="modal-campaign-overlay modal-campaign-toggle"></div>
  <div class="modal-campaign-wrapper modal-campaign-transition">
    <div class="modal-campaign-header">
      <button class="modal-campaign-close modal-campaign-toggle" (click)="changeModalStatus()">
        <svg
          class="icon-close icon" viewBox="0 0 32 32">
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
      <h2 class="modal-campaign-heading">Kampanyalar</h2>
    </div>

    <div class="modal-campaign-body">
      <div class="modal-campaign-content">
        <div class="campaign-select">
          <div class="campaigns ccolor">
            <label class="containerrad camplabel" (click)="selectMinute(15)"><strong>15</strong> Dakika
              <input class="campselect" type="radio" checked="checked" name="radio">
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel" (click)="selectMinute(20)"><strong>20</strong> Dakika
              <input class="campselect" type="radio" name="radio">
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel" (click)="selectMinute(30)"><strong>30</strong> Dakika
              <input class="campselect" type="radio" name="radio">
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel" (click)="selectMinute(60)"><strong>1</strong> Saat
              <input class="campselect" type="radio" name="radio">
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel" (click)="selectMinute(60 * 12)"><strong>12</strong> Saat
              <input class="campselect" type="radio" name="radio">
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
        </div>
        <div>
                    <span class="input-ctn">
                        <div class="select-style6">
                            <select (change)="updateSelectedCreditPackage($event)" id="selectCredit">
                                <ng-container *ngFor="let credit of creditPackages; trackBy: trackByFn">
                                    <option *ngFor="let childCredit of credit.campaignLimits" [value]="credit._id">
                                        <strong>{{credit.credit}}</strong> Kr. alana <strong>{{childCredit}}</strong>
                                        Kr. bedava.
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                    </span>
          <button class="tamam kampanyaac confirmation" (click)="startNewCampaign()">Kampanya Aç</button>
        </div>
        <div class="oldcamps"><span>Geçmiş Kampanyalar</span></div>
        <div class="mid-table-container" style="width:540px;">
          <table class="mid-table campaign-table" style="width: 540px;">
            <tbody *ngIf="campaigns?.docs">
            <tr *ngFor="let campaign of campaigns.docs; trackBy: trackByFn">
              <ng-container *ngIf="!campaign?.active">
                <td width="56">
                  <p>{{campaign._id | slice : 11 - 16}}</p>
                </td>
                <td width="126">
                  <p><strong>{{campaign.bonus}}</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong>{{campaign.duration}}</strong>
                  </p>
                </td>
                <td width="50">
                  <p><strong>{{campaign.sales}}</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>{{campaign.createdAt | date: 'dd.MM.yyyy - HH:mm'}} &nbsp;//&nbsp;
                    {{campaign.endAt | date: 'dd.MM.yyyy - HH:mm'}}</p>
                </td>
              </ng-container>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

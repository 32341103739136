export class NumberFormatMapper {

  static format(number) {
    if (number || number == '0') {
      let split = (number+"").split('.');
      let int = split[0];
      let dec;
      if (split[1]) {
        dec = split[1].slice(0, 1);
        return int + '.' + dec;
      }
      return int;
    }
  }

}

import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  users = [{
    id: "982372",
    username: "JackNJones",
    siteApp: "Cafecanlı Mobil",
    email: "yakalabakem@yandex.com",
    memberStatus: "Standart",
    status: "Ceza",
    credit: "1,104",
    totalCredit: "17,180",
    lastLogin: "03/07/2018 - 22:40"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "Cafecanlı App",
    email: "abdullahdede_84382hart@hotmail.com",
    memberStatus: "VIP",
    status: "Aktif",
    credit: "1,104",
    totalCredit: "17,180",
    lastLogin: "03/07/2018 - 22:40"
  }, {
    id: "982372",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    email: "yakalabakem@yandex.com",
    memberStatus: "PRM",
    status: "Kapa",
    credit: "1,104",
    totalCredit: "17,180",
    lastLogin: "03/07/2018 - 22:40"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "Cafecanlı Mobil",
    email: "dr.badboy3441@gmail.com",
    memberStatus: "Caylak",
    status: "Ceza",
    credit: "1,104",
    totalCredit: "17,180",
    lastLogin: "03/07/2018 - 22:40"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "Cafecanlı Web",
    email: "yakalabakem@yandex.com",
    memberStatus: "VIP",
    status: "Aktif",
    credit: "1,104",
    totalCredit: "17,180",
    lastLogin: "03/07/2018 - 22:40"
  }];

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getUsers(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/user?${params}`)
  }

  getUserDetail(id: string) {
    return this.http.get(`${this.apiUrl}manager/user/${id}`);
  }

  getUserStories(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/story?${params}`);
  }

  getUserMessages(id: number, limit) {
    return this.http.get(`${this.apiUrl}manager/user/${id}/messages?limit=${limit}&sort=createdAt:desc`);
  }

  getUserSpendings(obj, userId) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(this.apiUrl + `manager/money/spendings?${params}`);
  }

  getUserPurchases(obj, userId) {
    let newParams = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        newParams.append(key, obj[key]);
      }
    }
    let params = newParams.toString().replace('%2C', '&').replace('%3A', ':');

    return this.http.get(this.apiUrl + "manager/money/purchases?" + params);
  }

  patchMember(obj, id) {
    return this.http.patch(`${this.apiUrl}manager/user/${id}`, obj);
  }

  patchMemberStatus(obj, id) {
    return this.http.patch(`${this.apiUrl}manager/user/${id}/type`, obj);
  }

  deleteMemberStatus(id) {
    return this.http.delete(`${this.apiUrl}manager/user/${id}/type`);
  }


  userStatistic(id) {
    return this.http.get(`${this.apiUrl}manager/user/${id}/statistic`)
  }


  approvePhoto(userId) {
    return this.http.put(`${this.apiUrl}manager/user/${userId}/approve-picture`, {});
  }

  rejectPhoto(userId) {
    return this.http.put(`${this.apiUrl}manager/user/${userId}/reject-picture`, {});
  }



  deletePurchase(purchaseId) {
    return this.http.delete(`${this.apiUrl}manager/money/purchase/${purchaseId}`);
  };
}

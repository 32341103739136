import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getMessageStats() {
    return this.http.get(`${this.apiUrl}manager/statistic/message`);
  }


  getSales(obj){
   let params = new URLSearchParams();
   for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/statistic/sales/${obj}`);
  }

  getGeneralStats(obj?){
    // IN SWAGGER THERE IS NO PARAMETER
    // let params = new URLSearchParams();
    // for (let key in obj) {
    //   if (obj[key]) {
    //     params.append(key, obj[key]);
    //   }
    // }
    return this.http.get(`${this.apiUrl}manager/statistic/general`);
  }


  getStreamerStats(obj){
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/streamer/statistic?${params}`);
  }

  getEarningForStreamer(ownerId){
    return this.http.get(`${this.apiUrl}manager/statistic/earning/${ownerId}?sort=createdAt:desc`);
  }

  getHomeStats(){
    return this.http.get(`${this.apiUrl}manager/statistic/home`);
  }

}

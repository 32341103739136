<!--
  	   Embed the ngx-datatable component with following property bindings/values:
       1. sorttype - allow data to be sorted on multiple columns
       2. headerHeight - Set height of table header at 50 pixels
       3. footerHeight - Set height of table footer at 50 pixels
       4. rowHeight - Set height of table rows at 50 pixels (or 'auto')
       5. rows - Derives the data for the table rows from the component class
                 property of rows
       6. columns - Derives the names for the table columns from the component
                    class property of columns
       7. columnMode - Use of standard, flex or force - Force value makes columns
                       equidistant and span the width of the parent container
       8. limit - the number of records to display before paginating the results
-->
<div class="big-table-container">
    <ngx-datatable #mydatatable [rows]="rows" class="bootstrap big-table" [sortType]="'multi'" [limit]="limit"
        [loadingIndicator]="loadingIndicator" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
        [rowHeight]="'auto'" [columns]="columns" [reorderable]="reorderable">
        <ng-container *ngFor="let item of columns; trackBy: trackByFn">
            <ngx-datatable-column [name]="item.name">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <a *ngIf="item.name === 'username'" [routerLink]="['/user', value]">{{value}}</a>
                    <span *ngIf="item.name !== 'username'">{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
        </ng-container>
    </ngx-datatable>
</div>
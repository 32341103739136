import { NoticeService } from 'src/app/shared/services/notice.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AfterViewInit, OnDestroy, OnInit, Component } from '@angular/core';
@Component({
  selector: 'warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})

export class WarningModalComponent implements OnInit, OnDestroy, AfterViewInit {
  owner: any;
  penaltyDuration = 1;
  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private noticeService: NoticeService
  ){

  }
  ngOnInit(){
    this.owner = this.ngxSmartModalService.getModalData('warningModal');
  }

  ngOnDestroy(){

  }

  ngAfterViewInit(){

  }

  submit(){
    this.noticeService.postNotice({
      penaltyDurationType: 'days',
      penaltyDuration: this.penaltyDuration,
      owner: this.owner._id,
      ownerType: 'User',
    }).subscribe(data => {
      this.ngxSmartModalService.closeLatestModal();
    })
  }

  cancel(){
    this.ngxSmartModalService.closeLatestModal();
  }
}
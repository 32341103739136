import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guards/auth.guards';


const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'agency',
        loadChildren: () => import('./pages/agency/agency.module').then(m => m.AgencyModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'streamer',
        loadChildren: () => import('./pages/streamer/streamer.module').then(m => m.StreamerModule),
      },
      {
        path: 'business-partners',
        loadChildren: () => import('./pages/business-partners/business-partners.module').then(m => m.BusinessPartnersModule),
      },
      {
        path: 'announcement',
        loadChildren: () => import('./pages/announcement/announcement.module').then(m => m.AnnouncementModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule),
      },
      {
        path: 'photos',
        loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule),
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: 'members',
        loadChildren: () => import('./pages/members/members.module').then(m => m.MembersModule),
      },
      {
        path: 'managers',
        loadChildren: () => import('./pages/managers/managers.module').then(m => m.ManagersModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'notes',
        loadChildren: () => import('./pages/notes/notes.module').then(m => m.NotesModule),
      },
      {
        path: 'logs',
        loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, EMPTY, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagerService  {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getManager() {
   return this.http.get(`${this.apiUrl}manager/auth`);
  }

  getDummyManager() {
    return this.http.get(`${this.apiUrl}manager/dummy`);
  }

  getManagers() {
   return this.http.get(`${this.apiUrl}manager`);
  }
  addManager(value) {
   return this.http.post(`${this.apiUrl}manager`, value);
  }

  getManagerById(id: number){
    return this.http.get(`${this.apiUrl}manager/${id}`);
  }

  updateManager(managerId, obj) {
    return this.http.patch(`${this.apiUrl}manager/${managerId}`, obj);
  }

  updateManagerPermissions(managerId, obj) {
    return this.http.patch(`${this.apiUrl}manager/${managerId}/permissions`, obj)
  }

}

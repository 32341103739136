import { GetManager } from './shared/store/_action/get-manager';
import { SocketService } from './shared/services/socket.service';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthState } from './shared/store/_state/auth.state';
import { Select, Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { ClickService } from './shared/services/click.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cafecanli-admin';
  @Select(AuthState.token) token$: Observable<string>;

  constructor(
    private clickService: ClickService,
    private readonly socketService: SocketService,
    private readonly store: Store,
    ) { }

  ngOnInit(): void {
    document.addEventListener('click', (e) => {
      this.clickService.clickListener.next(e);
    });
    this.token$
    .pipe(
      filter(token  => !!token),
    ).subscribe(token => {
      this.socketService.connect(token);
    });
  }
}

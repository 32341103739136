import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DashboardService } from '../../services/dashboard.service';
import { StoryService } from '../../services/story.service';
import { StoryStatus } from '../../utils/story-status.enum';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  apiUrl = environment.prodUrl;
  stories;
  storyForm: FormGroup;
  limit = 0;
  story;
  clickOutside = false;

  constructor(public router: Router, private dashboardService: DashboardService, private fb: FormBuilder, private storyService: StoryService) { }

  ngOnInit(): void {
    this.storyForm = this.fb.group({
      limit: [this.limit],
      status: [StoryStatus.WAITING_APPROVE]
    });
    this.getStories();
  }

  getStories() {
    this.limit += 18;
    this.storyForm.patchValue({ limit: this.limit });
    // console.log(this.storyForm.value);
    this.dashboardService.getStories(this.storyForm.value).subscribe((data: any) => {
      // this.stories = data.docs.filter(story => story.approvedAt === undefined);
      this.stories = data;
      // console.log(data)
    })
  }

  selectedStory(story) {
    this.story = story;
    this.clickOutside = false;
    // console.log('story', story)
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  approveStory(id) {
    return this.storyService.approveStoryById(id).subscribe(data => {
      this.clickOutside = true;
      this.story = null;
      this.getStories();
    });
  }

  rejectStory(id) {
    return this.storyService.rejectStoryById(id).subscribe(data => {
      this.clickOutside = true;
      this.story = null;
      this.getStories();
    });
  }

  clickedOut(e) {
    if (e.target.className === 'modal-story-overlay modal-story-toggle'
      || e.target.className === 'modal-story-close modal-story-toggle') {
      this.clickOutside = true;
      this.story = null;
    }
  }
  openStoryOwnerProfile(story) {
    if ( story?.ownerType === 'Streamer') {
      this.router.navigate(['/streamer', story.owner.id]);
      return
    }
    this.router.navigate(['/members', story.owner.id]);
  }

}

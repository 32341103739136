import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  OnInit,
} from '@angular/core';
import { AuthState } from 'src/app/shared/store/_state/auth.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthStateModel } from 'src/app/shared/store/_model/auth.state.model';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {
  private currentUserPermissions;
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;
  @Select(AuthState.authUserPermissions) auth$: Observable<AuthStateModel>;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this.auth$.subscribe((permissions: any) => {
      if (permissions && permissions.length) {
        this.currentUserPermissions = permissions;
        this.updateView();
      }
    });
  }

  @Input('hasPermission')
  set hasPermission(val: any) {
    this.permissions = val;
    this.updateView();
  }

  @Input('hasPermissionOp')
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.currentUserPermissions && this.currentUserPermissions.length) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUserPermissions.find(
          (x) => x === checkPermission,
        );

        if (permissionFound) {
          hasPermission = true;

          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission;
  }
}

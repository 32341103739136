import { SetPublicStreamers, SetPrivateStreamers, StreamInfo, SetFakeStreamers } from './../store/_state/stream.state';
import { StatusColorPipe } from './../pipes/status-color.pipe';
import { filter, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { SharedService } from './shared.service';
import { Store } from '@ngxs/store';
import { ChatChannelEnum, ChatInfoDataInterface } from '../constants';
import { DeleteOfflineUser, SetOnlineUser } from '../store/_state/online-user.state';
import { DeleteOfflineManager, SetOnlineManager } from '../store/_state/online-manager.state';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _socket: Socket;

  constructor(private readonly sharedService: SharedService, private readonly store: Store) {}

  connect(token?: string) {
    if (this._socket) {
      return;
    }
    this._socket = new Socket({
      url: token ? environment.socketUrl + '?token=' + token : environment.socketUrl,
      options: { transports: ['websocket'] },
    });
    // this._subscribePublicEvents();
    if (token) {
      this._subscribe();
    }

    this.sharedService.userStatus = this._socket.fromEvent('userStatus');
    this.sharedService.managerStatus = this._socket.fromEvent('managerStatus');
    this.sharedService.streams = this._socket.fromEvent('streams');
    this.sharedService.streams.subscribe((streams) => {
      
      streams.sort((a,b) => parseInt(b.streamer.level) - parseInt(a.streamer.level))

      this.store.dispatch(new SetPublicStreamers(streams?.filter((s) => s.type === 'public')));
      this.store.dispatch(
        new SetPrivateStreamers(streams?.filter((s) => s.type === 'private' && !this.isStreamFake(s))),
      );
      this.store.dispatch(new SetFakeStreamers(streams?.filter((s) => s.type === 'private' && this.isStreamFake(s))));
    });
    this.setOnlineUserNumberOnSite();
    this.setOnlineManagerOnSite();
  }

  private isStreamFake(stream: StreamInfo): boolean {
    return stream.user._id === `fake-user-${stream.streamer._id}`;
  }
  setOnlineUserNumberOnSite() {
    this.sharedService.userStatus.subscribe((status) => {
      if (Array.isArray(status)) {
        status.forEach((s) => {
          this.store.dispatch(new SetOnlineUser({ ownerId: s.ownerId, online: s.online }));
          StatusColorPipe.statuses[s.ownerId] = s.online;
        });
      } else {
        //console.log('setOnlineUserNumberOnSite:else', status);
        if (status.online) {
          StatusColorPipe.statuses[status.ownerId] = status.online;
          this.store.dispatch(new SetOnlineUser({ ownerId: status.ownerId, online: status.online }));
        } else {
          this.store.dispatch(new DeleteOfflineUser({ ownerId: status.ownerId, online: status.online }));
          delete StatusColorPipe.statuses[status.ownerId];
        }
      }
    });
  }
  setOnlineManagerOnSite() {
    this.sharedService.managerStatus.subscribe((status) => {
      if (Array.isArray(status)) {
        status.forEach((s) => {
          this.store.dispatch(new SetOnlineManager({ ownerId: s.ownerId, online: s.online }));
          StatusColorPipe.statuses[s.ownerId] = s.online;
        });
      } else {
        //console.log('setOnlineManagerOnSite:else', status);
        if (status.online) {
          StatusColorPipe.statuses[status.ownerId] = status.online;
          this.store.dispatch(new SetOnlineManager({ ownerId: status.ownerId, online: status.online }));
        } else {
          this.store.dispatch(new DeleteOfflineManager({ ownerId: status.ownerId, online: status.online }));
          delete StatusColorPipe.statuses[status.ownerId];
        }
      }
    });
  }
  getSocket(): Socket {
    return this._socket;
  }

  emit(event: string, data?: any) {
    console.log('emitting:', event, data);
    this._socket.emit(event, data);
  }

  private _subscribe() {
    this.sharedService.chatParticipants = this._socket.fromEvent<object[]>(ChatChannelEnum.PARTICIPANTS);
    this.sharedService.chatMessage = this._socket.fromEvent<object[]>(ChatChannelEnum.MESSAGE);
    this.sharedService.chatJoined = this._socket.fromEvent<object[]>(ChatChannelEnum.JOINED);
    this.sharedService.chatInfo = this._socket.fromEvent<ChatInfoDataInterface>(ChatChannelEnum.INFO);
    this.sharedService.exception = this._socket.fromEvent<object>('exception');
  }

  private _subscribePublicEvents() {
    this.sharedService.userStatus = this._socket.fromEvent('userStatus').pipe(tap(console.log));
    this.sharedService.streams = this._socket.fromEvent('streams').pipe(tap(console.log));

    this.sharedService.streams
      .pipe(
        tap((streams) => console.log('streams:', streams)),
        // filter(streams => !!streams && streams?.length> 0)
      )
      .subscribe((streams) => {
        StatusColorPipe.streamers = streams?.reduce((acc, next) => {
          acc[next.streamer._id] = next.type;
          return acc;
        }, {});
      });

    this.sharedService.userStatus.subscribe((status) => {
      if (Array.isArray(status)) {
        status.forEach((s) => {
          StatusColorPipe.statuses[s.ownerId] = s.online;
        });
      } else {
        if (status.online) {
          StatusColorPipe.statuses[status.ownerId] = status.online;
        } else {
          delete StatusColorPipe.statuses[status.ownerId];
        }
      }
    });
  }
}

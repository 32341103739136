<div class="hizli-yukle">
    <h3>HızlıYükle</h3>
    <form [formGroup]="creditForm">
        <div class="autocomplete-ctn">
            <input autoComplete="off" class="hizli-user complete" type="text" formControlName="userName"
                placeholder="Kullanıcı adı" (input)="onChange()" name="userName">


            <ul *ngIf="users.length >= 1" class="member-list-hediye completeList" style="top: 50px;right: 11px;">
                <li *ngFor="let item of users;" (click)="setUser(item)"><a href="javascript:void;"><span
                            class="list-name">{{item.username}}</span><span class="list-number">#{{item.userId}}</span></a> </li>
            </ul>
            <!-- <ul class="member-list-hediye">
                <li><a href="javascript:void;"><span class="list-name">Nem</span><span class="list-number">231</span></a></li>
                <li><a href="javascript:void;"><span class="list-name">Nemservi</span><span class="list-number">532</span></a></li>
                <li><a href="javascript:void;"><span class="list-name">Nemrutun Kızı</span><span class="list-number">19.256</span></a></li>
                <li><a href="javascript:void;"><span class="list-name">Nemrut</span><span class="list-number">1,932</span></a></li>
           </ul>-->
        </div>
        <input class="hizli-mebla numberlimit1" type="text" formControlName="amount" placeholder="Mebla" maxlength="6"
            pattern="([0-9]|[0-9]|[0-9][0-9][0-9][0-9])">
        <div class="select-style">
            <select formControlName="transferType" (ngModelChange)="onChangeTransferType($event)">
                <option *ngFor="let item of creditTypes; trackBy: trackByFn" [value]="item.value">{{item.name}}</option>
            </select>
        </div>
        <div class="select-style2">
            <select formControlName="gateway">
                <option *ngFor="let item of paymentTypes; trackBy: trackByFn" [value]="item._id" selected="selected">
                    {{item.name}}</option>
            </select>
        </div>
        <input type="button" (click)="onSubmit()" value="" class="okbutton" />
    </form>

</div>

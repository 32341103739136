import { Injectable } from '@angular/core';
import { Login } from '../model/login';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { AuthStateModel } from '../store/_model/auth.state.model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  signin(email: string, password: string): Observable<AuthStateModel> {
    return this.http.post<Login>(this.apiUrl + "manager/auth/login", { email: email, password: password });
  }

  logout(type) {
    return of(type);
  }
}

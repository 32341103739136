<section id="stream" class="noselect" style="height: 574px; overflow:hidden; width: 1250px; margin:0 auto;">
  <div class="content">
    <div class="stream-left">
      <div class="player-container">
        <div class="preview-player">
          <video #previewPlayer style="width: 100%; height: 100%; object-fit: contain;" autoplay></video>
        </div>
        <div class="darken"></div>
        <div class="video-tools">
          <div class="sound" [ngClass]="{'sound-muted': soundMuted }" (click)="toggleSound()"></div>
          <div class="vopts">
            <p class="viduser"><span class="vusername">{{streamer?.streamerName}}</span><span class="vidid"> ID. {{
                streamer?.streamerId}}</span><br><span class="vidid">{{ streamer?.creditByPerMinute }}</span><span
                class="vidcinfo"> Kredi / Dakika</span></p>
          </div>
        </div>
        <div #chatArea class="chat-area">
          <div class="chat-message" *ngFor="let chat of chats; let messageIndex = index" @fadeIn>
            <b [ngClass]="{'ouser': chat?.owner?._id !== chatUser?._id, 'guser': chat?.gift}">{{chat?.owner?.username}}:
            </b>
            <span [ngClass]="{'system-message': chat?.owner?.system }" *ngIf="!chat?.gift">{{chat.message}}</span>
            <span class="gift-span" [style.background-image]="'url(/'+getGift(chat.gift).image+')'"
              *ngIf="chat?.gift">{{getGift(chat.gift)?.name}} Gönderdi</span>
          </div>
        </div>
        <ngx-spinner name="player" bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-pulse"
          [fullScreen]="false"></ngx-spinner>
      </div>
      <div class="chat-input">
        <textarea disabled spellcheck="false" [(ngModel)]="chatMessageBox"></textarea>
      </div>
      <div class="chat-send-message drop-stream" style="right: 85px;">
        <a (click)="dropStream()"  class="grayround2 grdark">Yayını Düşür</a>
      </div>
      <div class="chat-send-message">
        <a (click)="send()" class="grayround2 grdark">Gönder</a>
      </div>
    </div>
    <div class="stream-right">
      <div class="user-cam">
        <video #userPlayer autoplay muted></video>
      </div>
      <button class="stcambtn mb1" (click)="drop()">ODADAN AT</button>
      <button class="stcambtn" (click)="block()">ENGELLE</button>
      <button class="stcambtn" style="margin-right:0;" (click)="warning()">CEZA VER</button>
      <div class="photo-filters">
        <p class="userbig">{{stream?.user?.username}}</p>
        <p class="useradmininfo">{{getUserType()}} Üye</p>
        <p class="adminuserinfo"><span class="auserileft">Mevcut kredi</span><span class="auseriright">{{ user?.credit | toFixed }} Kr.</span>
        </p>
        <p class="adminuserinfo"><span class="auserileft">Toplam kredi</span><span class="auseriright">{{ user?.totalCredit | toFixed }}
            Kr.</span></p>
        <p class="adminuserinfo"><span class="auserileft">Giriş</span><span class="auseriright">{{ appType(user?.lastLoginInfo?.appType)}}</span>
        </p>
        <p class="adminuserinfo"><span class="auserileft">Giriş IP</span><span
            class="auseriright">{{ user?.lastLoginInfo?.ip }}</span></p>
        <p class="adminuserinfo"><span class="auserileft">Kayıt</span><span class="auseriright">{{ user?.registeredInfo?.createdAt}}</span></p>
        <p class="adminuserinfo" style="border-bottom: none;"><span class="auserileft">Referal</span><span
            class="auseriright">-</span></p>
      </div>
      <div class="filters-info warnbuttons">
        <a
          (click)="chatMessageBox = 'Servisimiz üzerinden iletişim bilgisi paylaşmak üyelik sözleşmemize aykırıdır. Bu uyarıyı dikkate almamanız ve tekrarlamanız durumunda üyeliğiniz ceza alabilir, devamında ise süresiz kapanabilir.'">İLETİŞİM</a>
        <a
          (click)="chatMessageBox = 'Yayıncı ve üye arasında Birebir Görüşmelerde yapılan özel konuşmaların genel yayınlara taşınması topluluk kurallarımıza aykırıdır. Üyeliğinizin ceza almaması için lütfen yayıncı ile aranızdaki özel diyalogları genel yayınlarda paylaşmayınız.'">KİŞİSEL</a>
        <a
          (click)="chatMessageBox='Servisimiz üzerinde küfür / argo / hakaret içeren yazışmalar topluluk kurallarımıza aykırı olup, üyeliğinizin dondurulmasına ya da süresiz kapatılmasına sebep olabilir. Lütfen bu uyarıyı dikkate alınız.'">ARGO</a>
        <a
          (click)="chatMessageBox='Servisimiz üzerinde, başka yayın platformları ve ticari markaların reklam amaçlı adının kullanılması üyelik sözleşmemize aykırıdır. Hesabınızın ceza almaması için lütfen bu uyarıyı dikkate alınız.'">REKLAM</a>
      </div>
    </div>

    <div class="clr"></div>
  </div>
</section>

import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OnlineManagerModel } from '../_model/onlineManager.state.model';

export class SetOnlineManager {
  static readonly type = '[Stream] Set Online Manager';
  constructor(public user: OnlineManagerInterface) {}
}
export class DeleteOfflineManager {
  static readonly type = '[Stream] Delete Online Manager';
  constructor(public user: OnlineManagerInterface) {}
}
export interface OnlineManagerInterface {
  ownerId: string;
  online: boolean;
}

@State<OnlineManagerModel>({
  name: 'onlineManagerList',
  defaults: {
    onlineManagerList: []
  },
})
@Injectable()
export class OnlineManagerState {
  @Selector()
  static getOnlineManagerList(state: OnlineManagerModel) {
    return state.onlineManagerList;
  }

  constructor() {}

  @Action(SetOnlineManager)
  setOnlineManager(ctx: StateContext<OnlineManagerModel>, user : SetOnlineManager) {
    ctx.getState().onlineManagerList.push(user?.user)
    ctx.setState({
      onlineManagerList: ctx.getState().onlineManagerList,
    });
  }
  @Action(DeleteOfflineManager)
  deleteOfflineManager(ctx: StateContext<OnlineManagerModel>, user : SetOnlineManager) {
    ctx.getState().onlineManagerList = ctx.getState().onlineManagerList.filter(item => item.ownerId !== user?.user.ownerId)
    ctx.setState({
      onlineManagerList: ctx.getState().onlineManagerList,
    });
  }
}

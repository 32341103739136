import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { PaymentService } from '../../services/payment.service';
import { GetWaitingPaymentNumber } from '../_action/getWaitingNumber';
import { PaymentStateModel } from '../_model/payment.state.model';

@State<PaymentStateModel>({
  name: 'waitingPayment',
})
@Injectable()
export class PaymentState {

  @Selector()
  static getWaitingNumber(state: PaymentStateModel) {
    return state.waitingNumber;
  }

  constructor(private paymentService: PaymentService) { }

  @Action(GetWaitingPaymentNumber)
  getWaitingPaymentNumber({ setState }: StateContext<PaymentStateModel>) {
    return this.paymentService.getPayments({}).pipe(tap((data: any) => {
      setState({
        payments: data,
        waitingNumber: data.waitingPaymentCount
      })
    }));
  }

}

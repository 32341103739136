import { StatusColorPipe } from './pipes/status-color.pipe';
import { PlayerTimePipe } from './pipes/player-time.pipe';
import { ToFixedPipe } from './pipes/to-fixed.pipe';
import { PrivateStreamComponent } from './components/stream/private-stream/private-stream.component';
import { WarningModalComponent } from './components/stream/warning-modal/warning-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PublicStreamComponent } from './components/stream/public-stream/public-stream.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { MiniCreditComponent } from './components/mini-credit/mini-credit.component';
import { NotesComponent } from './components/notes/notes.component';
import { SendGiftComponent } from './components/send-gift/send-gift.component';
import { StatsComponent } from './components/stats/stats.component';
import { StoriesComponent } from './components/stories/stories.component';
import { DiffInMinPipe } from './pipes/diff-in-min.pipe';
import { HasPermissionDirective } from 'src/app/directives/hasPermission.directive';
import { CanClickDirective } from 'src/app/directives/canClick.directive';


@NgModule({
  declarations: [
    MiniCreditComponent,
    SendGiftComponent,
    NotesComponent,
    StoriesComponent,
    StatsComponent,
    DataTableComponent,
    AddCampaignComponent,
    PublicStreamComponent,
    PrivateStreamComponent,
    WarningModalComponent,
    ToFixedPipe,
    PlayerTimePipe,
    StatusColorPipe,
    DiffInMinPipe,
    HasPermissionDirective,
    CanClickDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    RouterModule,
    FormsModule,
    NgxSpinnerModule,

  ], exports: [
    MiniCreditComponent,
    SendGiftComponent,
    NotesComponent,
    StoriesComponent,
    StatsComponent,
    DataTableComponent,
    AddCampaignComponent,
    PublicStreamComponent,
    PrivateStreamComponent,
    WarningModalComponent,
    ToFixedPipe,
    DiffInMinPipe,
    PlayerTimePipe,
    StatusColorPipe,
    HasPermissionDirective,
    CanClickDirective
  ],
  entryComponents: [
    PrivateStreamComponent,
    PublicStreamComponent,
  ]
})
export class SharedModule { }

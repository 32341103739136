import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClickService } from '../../services/click.service';
import { DataService } from '../../services/data.service';
import { GiftService } from '../../services/gift.service';
import { QuickSearchService } from '../../services/quick-search.service';

@Component({
  selector: 'app-send-gift',
  templateUrl: './send-gift.component.html',
  styleUrls: ['./send-gift.component.scss']
})
export class SendGiftComponent implements OnInit {


  sendGiftForm: FormGroup;
  giftUsers = [];
  streamers = [];


  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private quickSearchService: QuickSearchService,
    private giftService: GiftService,
    private clickService: ClickService
  ) { }

  ngOnInit(): void {

    this.sendGiftForm = this.fb.group({
      userName: [],
      user: [],
      streamer: [],
      streamerName: [],
      credit: [],
    });

    this.clickService.clickListener.subscribe((clickEvent) => {
      this.giftUsers = [];
      this.streamers = [];
    });
  }


  onChange(type: string) {
    if (type === 'userName') {
      if (this.sendGiftForm.get("userName").value.length >= 3) {
        this.quickSearchService.quickSearch(this.sendGiftForm.get("userName").value, "username", "user").subscribe(
          data => {
            this.giftUsers = data["docs"];
          }
        );
      }
    } else {
      if (this.sendGiftForm.get("streamerName").value.length >= 3) {
        this.quickSearchService.streamerQuickSearch(this.sendGiftForm.get("streamerName").value).subscribe(
          data => {
            this.streamers = data["docs"];
          }
        );
      }
    }

  }


  setUser(user) {
    this.giftUsers.length = 0;
    this.sendGiftForm.patchValue({ user: user["id"] });
    this.sendGiftForm.patchValue({ userName: user["username"] })
  }

  setStreamer(streamer) {
    this.streamers.length = 0;
    this.sendGiftForm.patchValue({ streamer: streamer["id"] });
    this.sendGiftForm.patchValue({ streamerName: streamer["streamerName"] })
  }


  onSubmit() {
    this.sendGiftForm.removeControl('userName');
    this.sendGiftForm.patchValue({ credit: parseInt(this.sendGiftForm.get('credit').value) });
    this.giftService.defineGift(this.sendGiftForm.value).pipe().subscribe(
      data => {
        this.dataService.dataServiceItem.next({ type: "gift"});
        this.sendGiftForm.addControl('userName', new FormControl('', Validators.required));
        this.sendGiftForm.reset();
      }
    )
  }
}

import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../services/stats.service';

import { CampaignService } from '../../services/campaign.service';
import { SettingsService } from '../../services/settings.service';
import { ManagerService } from '../../services/manager.service';
import { NumberFormatMapper } from '../../utils/number-format-mapper';
import { MonthMapper } from '../../utils/month-mapper';
import { DatetimeMapper } from '../../utils/datetime-mapper';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { StreamState, StreamInfo } from '../../store/_state/stream.state';
import { OnlineUserState } from '../../store/_state/online-user.state';
import { OnlineManagerState } from '../../store/_state/online-manager.state';
import { OnlineManagerModel } from '../../store/_model/onlineManager.state.model';

declare var $: any;

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  time;
  @Select(OnlineUserState.getOnlineUserList) onlineUserList$: Observable<OnlineUserState[]>;
  @Select(OnlineManagerState.getOnlineManagerList) onlineManagerList$: Observable<OnlineManagerState[]>;
  @Select(StreamState.publicStreamers) publicStreamers$: Observable<StreamInfo[]>;
  @Select(StreamState.privateStreamers) privateStreamers$: Observable<StreamInfo[]>;
  constructor(
    private statsService: StatsService,
    private campaignService: CampaignService,
    private settingsService: SettingsService,
    private managerService: ManagerService,
  ) {}

  ngOnInit(): void {
    this.time = new Date();
    this.getHomeStats();
    this.getCampaign();
    this.getActiveCampaign();
    this.getCredits();
    this.getPackages();
    this.getManager();
  }
  public fromMillisToHours(millis: number): string {
    return DatetimeMapper.fromMillisToHours(millis);
  }

  managers = new Set();

  getManager() {
    this.managerService.getManagers().subscribe((managers: any) => {
      this.onlineManagerList$.subscribe((onlineManager: any) => {
        onlineManager.forEach((online) => {
          const result = managers.filter((manager) => manager.id === online.ownerId);
          if (result.length > 0) {
            this.managers.add(result[0].username);
          }
        });
      });
    });
  }

  cancelCampaign(campaignId) {
    this.campaignService.deleteCampaign(campaignId).subscribe((data) => {
      // console.log(data);
      this.getActiveCampaign();
    });
  }

  homeStats;

  getHomeStats() {
    this.statsService.getHomeStats().subscribe((data) => {
      this.homeStats = data;
    });
  }

  formatNumber(number) {
    return NumberFormatMapper.format(number);
  }

  getTime(time) {
    let date = new Date(time);
    let day = date.getDate();
    let month = MonthMapper.map(date.getMonth());
    let year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  popUpOpen = false;

  openPopUp() {
    this.popUpOpen = true;
  }

  deleteOption() {
    this.popUpOpen = false;
  }

  cancelOption() {
    this.popUpOpen = false;
  }

  modalStatus: boolean = false;

  isVisible() {
    return this.modalStatus;
  }

  changeModalStatus() {
    this.modalStatus = !this.modalStatus;
  }

  minute: number = 15;

  selectMinute(minute) {
    this.minute = minute;
  }

  startNewCampaign() {
    this.campaignService
      .addCampaign({
        credit: this.selectedCreditPackage,
        bonus: this.selectedBonus,
        duration: this.minute,
      })
      .subscribe((data) => {
        this.getCampaign();
        this.getActiveCampaign();
        location.reload();
      });
  }

  selectedCreditPackage;
  selectedBonus: number;

  updateSelectedCreditPackage(event: any) {
    // console.log('event.target.value', event.target.value)
    this.selectedCreditPackage = event.target.value;
    const sel = <HTMLSelectElement>document.getElementById('selectCredit');
    const text = sel.options[sel.selectedIndex].text;
    this.selectedBonus = +text.split(' ').filter((el) => Number(el))[1];
  }

  leftTime;
  campaigns;

  getCampaign() {
    this.campaignService.getCampaign().subscribe((data: any) => {
      this.campaigns = data;
      // console.log("campaigns");
      // console.log(this.campaigns);
    });
  }

  activeCampaign;

  getActiveCampaign() {
    this.campaignService.getActiveCampaign().subscribe((data: any) => {
      this.activeCampaign = data;
      // console.log("activeCampaign");
      // console.log(this.activeCampaign);

      if (data?.endAt) {
        this.leftTime = (new Date(data?.endAt).getTime() - new Date().getTime()) / 1000;
        this.progress(+this.leftTime, +data.duration * 60);
      }
    });
  }

  creditPackages;

  getCredits() {
    this.settingsService.getCredits().subscribe((data) => {
      if (data) {
        this.creditPackages = data;
        // console.log("getCredits");
        // console.log(data);
        this.selectedCreditPackage = this.creditPackages[0]?._id;
        this.selectedBonus = this.creditPackages[0]?.campaignLimits[0];
      }
    });
  }

  getPackages() {
    this.settingsService.getPackages().subscribe((data) => {
      if (data) {
        // console.log("getPackages");
        // console.log(data);
      }
    });
  }

  trackByFn(item, index) {
    return index;
  }

  progress(timeleft, timetotal) {
    let self = this;

    $(document).ready(function () {
      var progressBarWidth = (timeleft * $('#progressBar').width()) / timetotal;
      $('#progressBar').find('div').animate({ width: progressBarWidth }, 500);
      if (timeleft > 0) {
        setTimeout(function () {
          self.progress(timeleft - 1, timetotal);
        }, 1000);
      }
      if (timeleft == 0) {
        $('#progressBar').addClass('blinking');
      }
      $('#progressBar')
        .find('span.countdown')
        .html(
          (Math.floor(timeleft / 3600) ? Math.floor(timeleft / 3600) + ':' : '') +
            Math.floor((timeleft % 3600) / 60) +
            ':' +
            (timeleft % 60).toFixed(),
        );
    });
  }
}

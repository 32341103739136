import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getNotes(obj): Observable<any> {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }

    return this.http.get(`${this.apiUrl}manager/note?${params}`);
  }

  addNote(obj): Observable<any> {
    return this.http.post(`${this.apiUrl}manager/note`, obj);
  }

  getUserNotes(owner, limit?): Observable<any> {
    let params = `user=${owner}`;
    if (limit) {
      params += `&limit=${limit}`;
    }

    return this.http.get(`${this.apiUrl}manager/note?${params}`);
  }

  deleteUserNote(noteId): Observable<any> {
    return this.http.delete(`${this.apiUrl}manager/note/${noteId}`);
  }

  patchNotes(ids): Observable<any> {
    return this.http.patch(`${this.apiUrl}manager/note`, ids);
  }

  answerNote(obj): Observable<any> {
    return this.http.post(`${this.apiUrl}manager/note`, obj);
  }
}

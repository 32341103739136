import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  todaySendedGifts = [{
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    streamer: "Storm Angel",
    streamerId: 2,
    credit: "100",
    hour: "17:41",
  }];


  registeredOfToday = [{
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }, {
    userId: 1,
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
  }]


  salesOfToday = [{
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "831712",
    username: "Baracuda7",
    siteApp: "GlobalSite",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "135TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }, {
    id: "982372",
    username: "SevenAdam91",
    siteApp: "CafeCanlı",
    registerRefLastRef: "Google Organic / Adres Bar",
    hour: "17:41",
    amount: "125TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Merchant Services"
  }, {
    id: "982372",
    username: "JackNJones",
    siteApp: "GlobalSite",
    registerRefLastRef: "Top50Sites / Google Organic",
    hour: "17:59",
    amount: "115TL",
    credit: "180 kr.",
    paymentType: "Kredi Kartı - Payby.me"
  }];



  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }


  getSalesOfToday(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/money/purchases?${params}`)
  }


  getPurchases(dateStr) {
    return this.http.get(`${this.apiUrl}manager/statistic/sales/${dateStr}`)
  }

  getRegisteredOfToday() {
    return of(this.registeredOfToday);
  }


  getTodaySendedGifts(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/gift?${params}`)
  }


  getTodaySentGiftsFromSpendings(obj) {
    let params = new URLSearchParams();
    params.append('types','Gift');
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/money/spendings?${params}`);
  }


  getStories(obj?) {
    if (obj) {
      let params = new URLSearchParams();
      for (let key in obj) {
        if (obj[key]) {
          params.append(key, obj[key]);
        }
      }
      // console.log(obj,params)
      return this.http.get(`${this.apiUrl}manager/story?${params}`);
    } else
      return this.http.get(`${this.apiUrl}manager/story`);
  }
}

import { StreamState } from './shared/store/_state/stream.state';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './shared/store/_state/auth.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { LayoutModule } from './shared/layout/layout.module';
import { ApiInterceptor } from './shared/helper/api.interceptor';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddCampaignComponent } from './shared/components/add-campaign/add-campaign.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UnapprovedState } from './shared/store/_state/unapproved-photo.state';
import { OutsideDirective } from './directives/outside.directive';
import { PaymentState } from './shared/store/_state/waiting-payment.state';
import { StreamerState } from './shared/store/_state/streamer.state';
import { ChartsModule } from 'ng2-charts';
import { OnlineUserState } from './shared/store/_state/online-user.state';
import { OnlineManagerState } from './shared/store/_state/online-manager.state';
@NgModule({
  declarations: [
    AppComponent, OutsideDirective
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    NgxsModule.forRoot([AuthState,OnlineUserState,OnlineManagerState, UnapprovedState, PaymentState, StreamerState, StreamState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.accessToken', 'auth.email', 'auth.name', 'auth.permissions']
    }),
    NgxsRouterPluginModule.forRoot(),
    HttpClientModule,
    SharedModule,
    DashboardModule,
    LayoutModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    ChartsModule
  ],
  exports: [OutsideDirective],
  entryComponents: [AddCampaignComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }

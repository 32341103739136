<div class="modal-header">
	<h4 class="modal-title">Hi there!</h4>
	<button type="button" class="close" aria-label="Close">
	  <span aria-hidden="true">&times;</span>
	</button>
  </div>
  <div class="modal-body">
	<p>Hello, {{name}}!</p>
  </div>
  <div class="modal-footer">
	<button type="button" class="btn btn-outline-dark">Close</button>
  </div>
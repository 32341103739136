<div id="container">
  <div class="content-middle">
    <div class="top-forms">
      <app-mini-credit></app-mini-credit>
      <app-send-gift></app-send-gift>
    </div>
    <div class="content-left">
      <div class="table-big-head">
        <h3>Bugün yapılan satışlar</h3>
        <h4>Son 50 ödeme</h4>
      </div>
      <div class="table-head-ctn">
        <table class="big-table big-table-head" style="width: 1012px">
          <tbody>
            <tr class="table-head">
              <td width="56">
                <p>ID</p>
              </td>
              <td width="126">
                <p>Kullanıcı Adı</p>
              </td>
              <td width="86">
                <p>Site / App</p>
              </td>
              <td width="275">
                <p>Kayıt Ref / Son Giriş Ref</p>
              </td>
              <td class="no-pad" width="61">
                <p>Saat</p>
              </td>
              <td class="no-pad" width="71">
                <p>Tutar</p>
              </td>
              <td class="no-pad" width="61">
                <p>Kredi</p>
              </td>
              <td width="200">
                <p>Ödeme Yöntemi</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="big-table-container"
        *ngIf="salesOfTodayRows"
        style="max-height: none; min-height: auto"
        [ngStyle]="{
          height: salesOfTodayRows.length > 10 ? '308px' : 'fit-content'
        }"
      >
        <table class="big-table" style="width: 1012px">
          <tbody>
            <tr
              *ngFor="
                let sale of salesOfTodayRows;
                trackBy: trackByFn;
                let i = index
              "
              [ngClass]="{
                bonus: sale.isBonus,
                'cancelled-sale': sale.status === 'cancelled'
              }"
            >
              <td width="56" id="credit-id-td">
                <p>{{ sale.owner.userId }}</p>
                <div class="table-hover">
                  <p class="head">
                    <span
                      class="left"
                      style="cursor: pointer"
                      [routerLink]="['/members/', sale.owner._id]"
                      >{{ sale.owner.username }}</span
                    ><span class="right"
                      >{{ getFormattedNumber(sale.owner.credit) }} Kr</span
                    >
                  </p>
                  <p
                    style="
                      border-bottom: 1px solid #bdcbd3 !important;
                      padding-bottom: 0 !important;
                      padding-top: 0 !important;
                      line-height: 1;
                      height: 0;
                    "
                  ></p>
                  <p>
                    <span class="left">Toplam aldığı kredi:</span
                    ><span class="right"
                      >{{ getFormattedNumber(sale.owner.totalCredit) }} Kr</span
                    >
                  </p>
                  <p>
                    <span class="left">Kayıt tarihi:</span
                    ><span class="right">{{
                      sale.owner.createdAt | date: 'dd.MM.yyyy - HH:mm'
                    }}</span>
                  </p>
                  <p>
                    <span class="left">Son giriş IP:</span
                    ><span class="right">{{
                      sale.owner?.lastLoginInfo?.ip
                    }}</span>
                  </p>
                  <p>
                    <span class="left">E-Posta:</span
                    ><span
                      class="right"
                      [ngClass]="{ verified: sale.owner.emailVerified }"
                      >{{ sale.owner.email }}</span
                    >
                  </p>
                </div>
              </td>
              <td class="user" width="126">
                <p>
                  <a [routerLink]="['/members/', sale.owner._id]">{{
                    sale.owner.username
                  }}</a>
                </p>
              </td>
              <td width="86">
                <p>
                  {{ sale?.appType || sale?.owner?.lastLoginInfo?.appType }}
                </p>
              </td>
              <td width="275">
                <p>
                  {{
                    getReferers(
                      sale?.owner?.registeredInfo?.referer,
                      sale?.owner?.lastLoginInfo?.referer
                    )
                  }}
                </p>
              </td>
              <td width="46">
                <p>{{ sale?.createdAt | date: 'HH:mm' }}</p>
              </td>
              <td class="center" width="71">
                <p>
                  {{ sale.price }}
                  <span class="small">{{ sale.currency | uppercase }}</span>
                </p>
              </td>
              <td
                width="61"
                class="center"
                [ngClass]="{
                  vip:
                    (sale?.basedOn?.type || sale.owner?.type?.type) === 'vip' &&
                    sale?.type !== 'Credit',
                  bc:
                    (sale?.basedOn?.type || sale.owner?.type?.type) ===
                      'premium' && sale?.type !== 'Credit'
                }"
              >
                <p>
                  {{ sale.value | number: '1.0-1' }}
                  <span class="small">{{
                    sale.type === 'Credit'
                      ? 'Kr.'
                      : getUserType(sale?.basedOn?.type)
                  }}</span>
                </p>
              </td>
              <td width="200">
                <p>
                  {{
                    sale.isBonus
                      ? sale.isReferer
                        ? 'Referans Bonusu'
                        : sale?.manager?.username
                        ? 'Bonus - ' + sale?.manager?.username
                        : 'Bonus'
                      : getPaymentType(
                          sale.gateway?.type,
                          sale?.gateway,
                          sale?.manager?.username
                        )
                  }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="border-bottom"></div>
      <div class="big-table-bottom">
        <a [routerLink]="['/reports/today-credits']">Tümünü Göster</a>
        <a class="updown up" style="cursor: pointer"></a>
      </div>
      <div class="model-boxes">
        <span *hasPermission="['public-room-management']" class="public-models">
          <div *ngFor="let stream of publicStreamers$ | async">
            <div
              class="model-box public-room level-{{ stream?.streamer.level }}"
            >
              <div *ngIf="stream?.streamer.level == 0" class="new-label">
                <img src="assets/img/new-streamer.png" width="80" />
              </div>
              <div class="inner">
                <div class="img-ctn">
                  <div
                    class="stream-image"
                    (click)="openPublicStream(stream?.streamer)"
                    [ngStyle]="{
                      'background-image': bgProfilePhoto(
                        stream?.streamer.profile?.picture?.path
                      )
                    }"
                  ></div>
                  <img
                    [src]="
                      stream?.streamer?.profile?.represent
                        ? '/assets/img/gf.png'
                        : '/assets/img/gf.png'
                    "
                    alt=""
                    class="realpic"
                    (click)="openPublicStream(stream?.streamer)"
                  />
                  <!-- public odadaki model kutusu üzerine mouseoverda açılacak pencere-->
                  <div class="room-hover public-hover">
                    <p
                      class="head"
                      *ngIf="getParticipantCounts(stream) as counts"
                    >
                      <span class="left">Oda:</span
                      ><span class="right"
                        >{{ counts.guest }} M - {{ counts.user }} U</span
                      >
                    </p>
                    <p *ngFor="let participant of getParticipants(stream)">
                      <span class="left"
                        ><a
                          (click)="
                            $event.stopPropagation();
                            $event.preventDefault();
                            goToMember(participant?.owner?._id)
                          "
                          class="clickable"
                          >{{ participant?.owner?.username }}</a
                        ></span
                      ><span class="right">{{
                        appType(participant?.owner?.lastLoginInfo?.appType)
                      }}</span>
                    </p>

                    <div class="room-info">
                      <!-- <p *ngIf="stream?.streamer?.parentStreamer"><span class="left">Ajans / Referrer:</span><span class="right"><a
                                                    href="javascript:void;" class="clickable">{{stream?.streamer?.parentStreamer?.username}}</a></span></p> -->
                      <p>
                        <span class="left">Genel oda süresi:</span
                        ><span class="right">{{
                          stream?.startedAt | diffInMin | playerTime
                        }}</span>
                      </p>
                    </div>
                    <p class="room-users">
                      {{ getManagerParticipants(stream) }}
                    </p>
                  </div>
                  <!-- pencere sonu-->
                </div>
                <p>
                  <span class="count">{{
                    getParticipants(stream)?.length
                  }}</span
                  ><span class="site">CafeCanlı</span
                  ><span class="letter">{{ stream?.streamer.level }}</span>
                </p>
              </div>
              <p class="model-name">
                <a [routerLink]="['/streamer/' + stream?.streamer?._id]">{{
                  stream?.streamer?.streamerName
                }}</a>
              </p>
            </div>
          </div>
        </span>

        <span
          *hasPermission="['private-room-management']"
          class="private-models"
        >
          <div *ngFor="let stream of privateStreamers$ | async">
            <div
              class="model-box private-room private level-{{
                stream?.streamer.level
              }}"
            >
              <div *ngIf="stream?.streamer.level == 0" class="new-label">
                <img src="assets/img/new-streamer.png" width="80" />
              </div>
              <div class="inner">
                <div class="img-ctn">
                  <div
                    class="stream-image"
                    [ngStyle]="{
                      'background-image': bgProfilePhoto(
                        stream?.streamer.profile?.picture?.path
                      )
                    }"
                    (canClick)="openPrivateStream(stream?.streamer)"
                    [clickPermission]="'private-stream-watch'"
                  ></div>
                  <!-- private odadaki model kutusu üzerine mouseoverda açılacak pencere-->
                  <div class="room-hover private-hover">
                    <p class="head"><span class="left">BireBir Sohbet</span></p>
                    <p>
                      <span class="left"
                        ><a
                          (click)="
                            $event.stopPropagation();
                            $event.preventDefault();
                            goToMember(stream?.user?._id)
                          "
                          class="clickable"
                          >{{ stream?.user?.username }}</a
                        ></span
                      ><span class="right">{{
                        getSource(stream?.user?.source)
                      }}</span>
                    </p>
                    <div class="room-info">
                      <!-- <p><span class="left">Ajans / Referrer:</span><span class="right">N/A</span></p> -->
                      <p>
                        <span class="left">Birebir Süresi:</span
                        ><span class="right">{{
                          stream?.startedAt | diffInMin | playerTime
                        }}</span>
                      </p>
                      <p>
                        <span class="left">Üye kalan kredi:</span
                        ><span class="right"
                          >{{ stream?.user?.credit | toFixed }} Kr.</span
                        >
                      </p>
                    </div>
                    <p class="room-users">
                      {{ getManagerParticipants(stream) }}
                    </p>
                  </div>
                  <!-- pencere sonu-->
                </div>

                <p>
                  <span class="count">1</span><span class="site">CafeCanlı</span
                  ><span class="letter">{{ stream?.streamer.level }}</span>
                </p>
              </div>
              <p class="model-name">
                <a [routerLink]="['/streamer/' + stream?.streamer?._id]">{{
                  stream?.streamer?.streamerName
                }}</a>
              </p>
            </div>
          </div>
        </span>
        <div
          class="model-box private-room fake-room private-q1"
          *ngFor="let stream of fakeStreamers$ | async"
        >
          <div class="inner">
            <div class="img-ctn">
              <div
                class="stream-image"
                (canClick)="openPrivateStream(stream?.streamer)"
                [clickPermission]="'private-stream-watch'"
                [ngStyle]="{
                  'background-image': bgProfilePhoto(
                    stream?.streamer?.profile?.picture?.path
                  )
                }"
              ></div>
              <!-- private odadaki model kutusu üzerine mouseoverda açılacak pencere-->
              <div class="room-hover private-hover">
                <p class="head"><span class="left">BireBir Sohbet</span></p>
                <p>
                  <span class="left"
                    ><a
                      (click)="
                        $event.stopPropagation();
                        $event.preventDefault();
                        goToMember(stream?.user?._id)
                      "
                      class="clickable"
                      >{{ stream?.user?.username }}</a
                    ></span
                  ><span class="right">{{
                    getSource(stream?.user?.source)
                  }}</span>
                </p>
                <div class="room-info">
                  <!-- <p><span class="left">Ajans / Referrer:</span><span class="right">N/A</span></p> -->
                  <p>
                    <span class="left">Birebir Süresi:</span
                    ><span class="right">{{
                      stream?.startedAt | diffInMin | playerTime
                    }}</span>
                  </p>
                  <p>
                    <span class="left">Üye kalan kredi:</span
                    ><span class="right"
                      >{{ stream?.user?.credit | toFixed }} Kr.</span
                    >
                  </p>
                </div>
                <p class="room-users">{{ getManagerParticipants(stream) }}</p>
              </div>
              <!-- pencere sonu-->
            </div>

            <p>
              <span class="count">1</span><span class="site">CafeCanlı</span
              ><span class="letter">M</span>
            </p>
          </div>
          <p class="model-name">
            <a [routerLink]="['/streamer/' + stream?.streamer?._id]">{{
              stream?.streamer?.streamerName
            }}</a>
          </p>
        </div>

        <div class="clr"></div>
      </div>
      <!-- Bugün üye olanlar tablosu -->
      <div class="mid-table-container">
        <div class="table-mid-head">
          <h3>Bugün üye olan kullanıcılar</h3>
        </div>
        <div class="mid-table-head-ctn">
          <table
            class="big-table big-table-head mid-table-head"
            style="width: 522px"
          >
            <tbody>
              <tr class="table-head">
                <td width="105">
                  <p>Kullanıcı Adı</p>
                </td>
                <td width="77">
                  <p>Site / App</p>
                </td>
                <td width="232">
                  <p>Kayıt Ref</p>
                </td>
                <td width="46">
                  <p>Saat</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mid-table-container" *ngIf="registeredOfTodayRows">
          <table class="mid-table" style="width: 522px">
            <tbody>
              <tr
                *ngFor="
                  let register of registeredOfTodayRows.slice(0, 8);
                  trackBy: trackByFn
                "
              >
                <td class="name" width="105">
                  <p>
                    <a [routerLink]="['/members/', register._id]">{{
                      register.username
                    }}</a>
                  </p>
                </td>
                <td width="77">
                  <p>{{ appType(register.registeredInfo?.appType) }}</p>
                </td>
                <td width="232">
                  <p>{{ parseReferrer(register?.registeredInfo?.referer) }}</p>
                </td>
                <td width="46">
                  <p>
                    {{ register.registeredInfo?.createdAt | date: 'HH:mm' }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mid-table-bottom">
            <a [routerLink]="['/reports/today-registereds']">Tümünü Göster</a>
          </div>
        </div>
        <div class="clr"></div>
      </div>
      <!-- Bugün üye olanlar tablosu sonu -->

      <!-- Bugün gönderilen hediyeler tablosu -->
      <div class="mid-table-container" style="margin-left: 6px">
        <div class="table-mid-head">
          <h3>Bugün gönderilen hediyeler</h3>
        </div>
        <div class="mid-table-head-ctn">
          <table
            class="big-table big-table-head mid-table-head"
            style="width: 522px"
          >
            <tbody>
              <tr class="table-head">
                <td width="107">
                  <p>Kullanıcı Adı</p>
                </td>
                <td width="76">
                  <p>Site / App</p>
                </td>
                <td width="172">
                  <p>Yayıncı Adı</p>
                </td>
                <td width="61" class="center">
                  <p>Kredi</p>
                </td>
                <td width="44">
                  <p>Saat</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mid-table-container">
          <table class="mid-table" style="width: 522px" *ngIf="todaySentGifts">
            <tbody>
              <tr
                *ngFor="
                  let gift of todaySentGifts.slice(0, 8);
                  trackBy: trackByFn
                "
              >
                <td class="name" width="105">
                  <p>
                    <a [routerLink]="['/members/', gift.item?.user._id]">{{
                      gift.item?.user.username
                    }}</a>
                  </p>
                </td>
                <td width="76">
                  <p>{{ gift.appType }}</p>
                </td>
                <td class="name" width="172">
                  <p>
                    <a [routerLink]="['/streamer/', gift.item?.streamer._id]">{{
                      gift.item?.streamer.streamerName
                    }}</a>
                  </p>
                </td>
                <td width="61" class="center">
                  <p>{{ gift.item?.credit }} <span class="small">Kr.</span></p>
                </td>
                <td width="44">
                  <p>{{ gift.createdAt | date: 'HH:mm' }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mid-table-bottom">
            <a [routerLink]="['/reports/sent-gifts']">Tümünü Göster</a>
          </div>
        </div>
        <div class="clr"></div>
      </div>
      <!-- Bugün gönderilen hediyeler tablosu sonu -->
      <div class="clr"></div>
    </div>
    <div class="content-right">
      <app-stats *hasPermission="['detailed-statistics']"></app-stats>
      <app-stories></app-stories>
      <app-notes></app-notes>
    </div>
    <div class="clr"></div>
  </div>

  <div class="clr"></div>
</div>
<div class="clr"></div>

<div class="modal">
  <div class="modal-overlay modal-toggle"></div>
  <div class="modal-wrapper modal-transition">
    <div class="modal-header modal-success">
      <!-- hata durumunda modal-fail classı gelecek -->
      <button class="modal-close modal-toggle">
        <svg class="icon-close icon" viewBox="0 0 32 32">
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
      <h2 class="modal-heading">Başarılı işlem</h2>
    </div>

    <div class="modal-body">
      <div class="modal-content">
        <p>
          <span>whitefox</span> nickli kullanıcıya <span>40 Kr.</span> bonus
          yüklemesi gerçekleştirildi.
        </p>
        <button class="modal-toggle tamam">Tamam</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-story">
  <div class="modal-story-overlay modal-story-toggle"></div>
  <div class="modal-story-wrapper modal-story-transition">
    <div class="modal-story-header">
      <button class="modal-story-close modal-story-toggle">
        <svg class="icon-close icon" viewBox="0 0 32 32">
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
    </div>

    <div class="modal-story-body">
      <div class="modal-story-content">
        <a href="javascript:void;" class="story affected instory">
          <div class="storypic">
            <img src="../../../../assets/img/pp1.png" />
          </div>
          <p class="story-username">Sıfır Tölerans</p>
          <p class="story-date">19.01.2018, 20:54</p>
        </a>
        <img src="../../../../assets/img/storydummy.jpg" />
        <div class="modal-story-buttons">
          <button class="modal-story-toggle tamam onayla">Onayla</button>
          <button class="modal-story-toggle tamam reddet">Reddet</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-campaign">
  <div class="modal-campaign-overlay modal-campaign-toggle"></div>
  <div class="modal-campaign-wrapper modal-campaign-transition">
    <div class="modal-campaign-header">
      <button class="modal-campaign-close modal-campaign-toggle">
        <svg class="icon-close icon" viewBox="0 0 32 32">
          <use xlink:href="#icon-close"></use>
        </svg>
      </button>
      <h2 class="modal-campaign-heading">Kampanyalar</h2>
    </div>

    <div class="modal-campaign-body">
      <div class="modal-campaign-content">
        <div class="campaign-select">
          <div class="campaigns ccolor">
            <label class="containerrad camplabel"
              ><strong>15</strong> Dakika
              <input
                class="campselect"
                type="radio"
                checked="checked"
                name="radio"
              />
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel"
              ><strong>20</strong> Dakika
              <input class="campselect" type="radio" name="radio" />
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel"
              ><strong>30</strong> Dakika
              <input class="campselect" type="radio" name="radio" />
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel"
              ><strong>1</strong> Saat
              <input class="campselect" type="radio" name="radio" />
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
          <div class="campaigns">
            <label class="containerrad camplabel"
              ><strong>12</strong> Saat
              <input class="campselect" type="radio" name="radio" />
              <span class="checkmarkrad campaignrad"></span>
            </label>
          </div>
        </div>
        <div>
          <div class="input-ctn">
            <div class="select-style6">
              <select>
                <option value="ortak">
                  <strong>120</strong> Kr. alana <strong>30</strong> Kr. bedava.
                </option>
              </select>
            </div>
          </div>
          <button class="tamam kampanyaac confirmation">Kampanya Aç</button>
        </div>
        <div class="oldcamps">
          <span>Geçmiş Kampanyalar</span> <a class="all-camps">Tümünü göster</a>
        </div>
        <div class="mid-table-container" style="width: 540px">
          <table class="mid-table campaign-table" style="width: 540px">
            <tbody>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>120</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >30</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>2</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>25</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >35</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>17</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>120</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >30</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>2</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>25</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >35</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>17</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>120</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >30</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>2</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>25</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >35</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>17</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>120</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >30</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>2</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>25</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >35</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>17</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>120</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >30</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>2</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
              <tr>
                <td width="56">
                  <p>982372</p>
                </td>
                <td width="126">
                  <p>
                    <strong>25</strong>&nbsp;&nbsp;-&nbsp;&nbsp;<strong
                      >35</strong
                    >
                  </p>
                </td>
                <td width="50">
                  <p><strong>17</strong> <span class="small">Satış</span></p>
                </td>
                <td width="238">
                  <p>09/01/2019 - 18:30 &nbsp;//&nbsp; 09/01/2019 - 18:45</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

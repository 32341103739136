import { Injectable } from '@angular/core';
import { ChatChannelEnum, ChatInviteActionEnum, ChatInviteDataInterface, ChatMessagePaylaodInterface } from '../constants';
import { SocketService } from './socket.service';


@Injectable({
  providedIn: 'root',
})
export class ChatService {

  constructor(private socket: SocketService) {

  }


  public sendChatMessage(payload: ChatMessagePaylaodInterface) {
    this.socket.emit(ChatChannelEnum.MESSAGE, payload);
  }

  getParticipants() {
    this.socket.emit('getParticipants');
  }

  join(roomId: string) {
    this.socket.emit(ChatChannelEnum.JOIN, { roomId });
  }

  leave(roomId: string) {
    this.socket.emit(ChatChannelEnum.LEAVE, { roomId });
  }

  ban(userId: string) {
    this.socket.emit(ChatChannelEnum.BAN, { userId });
  }

  drop(userId: string) {
    this.socket.emit(ChatChannelEnum.DROP, { userId });
  }


  sendPrivateChatRequest(){
    this.socket.emit(ChatChannelEnum.INVITE, { action: ChatInviteActionEnum.Request, });
  }

  rejectPrivateChatRequest(data: ChatInviteDataInterface){
    this.socket.emit(ChatChannelEnum.INVITE, { action: ChatInviteActionEnum.Reject, userSocketId: data.userSocketId });
  }

  acceptPrivateChatRequest(data: ChatInviteDataInterface){
    this.socket.emit(ChatChannelEnum.INVITE, { action: ChatInviteActionEnum.Accept, userSocketId: data.userSocketId, streamId: data.streamId });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoryService {
  apiUrl = environment.prodUrl;

  constructor(private http:HttpClient) { }

  approveStoryById(storyId){
    return this.http.put(`${this.apiUrl}manager/story/${storyId}/approve`,{});
  }

  rejectStoryById(storyId){
    return this.http.put(`${this.apiUrl}manager/story/${storyId}/reject`, {});
  }
}

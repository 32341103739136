<div class="private layout">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#934d4d" type="ball-8bits"></ngx-spinner>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>

    <div class="modal" [ngClass]="{'is-visible':isVisible()}">
        <div class="modal-overlay modal-toggle"></div>
        <div class="modal-wrapper modal-transition">
            <div class="modal-header" [ngClass]="className">
                <!-- hata durumunda modal-fail classı gelecek -->
                <button class="modal-close modal-toggle" (click)="closeModal()"><svg class="icon-close icon"
                        viewBox="0 0 32 32">
                        <use xlink:href="#icon-close"></use>
                    </svg></button>
                <h2 class="modal-heading">{{className === 'modal-success' ? 'Başarılı İşlem' : 'Hatalı İşlem' }} </h2>
            </div>

            <div class="modal-body">
                <div class="modal-content">
                    <p>
                       {{ modalMessage }}
                    </p>
                    <button class="modal-toggle tamam" (click)="closeModal()">Tamam</button>
                </div>
            </div>
        </div>
    </div>
</div>
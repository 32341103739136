<div class="notes" *ngIf="notes">
    <div class="notes-head"><span class="left">Notlar</span><span class="right"><span>{{notes.unseen}}</span>
            Okunmamış</span></div>
    <ul class="notes-all">
        <ng-container *ngFor="let note of notes.docs;">
            <li class="replied" *ngIf="!note.seen">
                <a [routerLink]="['/notes']">
                    <p class="author">{{note.createdBy.username}}</p>
                    <p>{{note.content}}</p>
                </a>
            </li>
            <li class="read" *ngIf="note.seen">
                <a [routerLink]="['/notes']">
                    <p class="author">{{note.createdBy.username}}</p>
                    <p>{{note.content}}</p>
                </a>
            </li>
        </ng-container>
    </ul>
    <p class="notes-bottom"><a [routerLink]="['/notes']">Tüm notlar</a>
</div>
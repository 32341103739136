<div class="stories" *ngIf="stories">
  <ng-container *ngFor="let story of stories.docs; trackBy: trackByFn">
    <a class="story affected" (click)="selectedStory(story)">
      <div class="storypic">
        <img
          [src]="
            [story?.image?.path] || ('/assets/img/' + (story?.ownerType === 'Streamer'
            ? 'Streamer.png'
            : 'Member.png'))
          "
          class="story-pic"
        />
        <span class="graydot {{ story?.owner?._id | statusColor: story?.ownerType:'s' }}"></span>
      </div>
      <p class="story-username">
        {{ story?.ownerType === 'Streamer' ? story?.owner?.streamerName : story?.owner?.username }}
      </p>
    </a>
  </ng-container>
  <a *ngIf="stories.totalDocs > stories.docs.length" class="story affected">
    <div class="morestories" (click)="getStories()">
      <p>+{{ stories.totalDocs - stories.docs.length >= 18 ? 18 : stories.totalDocs - stories.docs.length }}</p>
    </div>
  </a>
</div>

<div
  class="modal-story"
  *ngIf="story"
  [ngClass]="{ 'is-visible': !clickOutside }"
  appOutside
  (click)="clickedOut($event)"
>
  <div class="modal-story-overlay modal-story-toggle"></div>
  <div class="modal-story-wrapper modal-story-transition">
    <div class="modal-story-header"></div>

    <div class="modal-story-body">
      <div class="modal-story-content">
        <div class="story affected instory">
          <div (click)="openStoryOwnerProfile(story)" class="storypic">
            <img
              [src]="story?.owner?.profile?.picture?.path?
                story?.owner?.profile?.picture?.path : ('/assets/img/' + (story?.ownerType === 'Streamer'
                ? 'Streamer.png'
                : 'Member.png'))
              "
              class="story-pic"
            />
          </div>
          <p class="story-username">
            {{ story?.ownerType === 'Streamer' ? story?.owner?.streamerName : story?.owner?.username }}
          </p>
          <p class="story-title">{{ story?.title }}</p>
          <p class="story-date">{{ story?.createdAt | date: 'dd.MM.yyyy - HH:mm' }}</p>
        </div>
        <img [src]="[story?.image?.path]" width="270px" height="480px" />
        <div class="modal-story-buttons">
          <button class="modal-story-toggle tamam onayla" (click)="approveStory(story._id)">Onayla</button>
          <button class="modal-story-toggle tamam reddet" (click)="rejectStory(story._id)">Reddet</button>
        </div>
      </div>
    </div>
  </div>
</div>

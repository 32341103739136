import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {


  editing = {};
  @Input() rows = [];
  @Input() columns = [];
  @Input() limit:number = 150;

  loadingIndicator: boolean = true;
  reorderable: boolean = true;


  // applyStyles = true;

  // cell, single, multiClick, checkbox, multi
  // selectionType = SelectionType.checkbox;

  constructor() {

    setTimeout(() => { this.loadingIndicator = false; }, 5000);

    this.fetch((data) => {
    //  this.rows = data;
    });

  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'https://unpkg.com/@swimlane/ngx-datatable@6.3.0/assets/data/company.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }

  ngOnInit() {

    // this.rows = [
    //   { name: 'Austin', gender: 'Male', company: 'Swimlane' },
    //   { name: 'Dany', gender: 'Male', company: 'KFC' },
    //   { name: 'Molly', gender: 'Female', company: 'Burger King' },
    // ];

   /*  this.columns = [
      { prop: 'name' },
      { name: 'Gender' },
      { name: 'Company' }
    ]; */

  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', rowIndex)
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }



  trackByFn(index, item) {
    return index; // or item.id
  }


}

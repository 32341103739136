import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ClickService } from '../../services/click.service';
import { CreditService } from '../../services/credit.service';
import { DataService } from '../../services/data.service';
import { QuickSearchService } from '../../services/quick-search.service';

@Component({
  selector: 'app-mini-credit',
  templateUrl: './mini-credit.component.html',
  styleUrls: ['./mini-credit.component.scss']
})
export class MiniCreditComponent implements OnInit {


  creditForm: FormGroup;
  users = [];

  creditTypesNotChangecable;
  creditTypes = [{
    name: "Kr. Satış",
    value: "credit"
  }, {
    name: "Bonus",
    value: "bonus"
  }, {
    name: "VIP",
    value: "vip"
  }, {
    name: "PRM",
    value: "premium"
  }];

  paymentTypes;

  trackByFn(index, item) {
    return index; // or item.id
  }

  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
    private creditService: CreditService,
    private quickSearchService: QuickSearchService,
    private clickService: ClickService
  ) {

  }



  ngOnInit() {
    this.creditTypesNotChangecable = this.creditTypes;
    this.creditForm = this.fb.group({
      userName: ["", Validators.required],
      user: [],
      amount: ["", Validators.required],
      transferType: ["credit", Validators.required],
      gateway: ["", Validators.required]
    });
    this.getGateway();
    this.clickService.clickListener.subscribe(clickEvent => {
      this.users = [];
    });
  }

  removeItemsFromCreditType(type) {
    if (type === "vip") {
      this.creditTypes = this.creditTypesNotChangecable.filter(data => data.value != "premium");
    } else if (type === "premium") {
      this.creditTypes = this.creditTypesNotChangecable.filter(data => data.value != "vip");
    } else {
      return this.creditTypes = this.creditTypesNotChangecable;
    }
  }

  getGateway() {
    this.quickSearchService.getGateways().subscribe(data => {
      this.paymentTypes = data;
    })
  }

  onChange() {
    if (this.creditForm.get("userName").value.length >= 3) {
      this.quickSearchService.quickSearch(this.creditForm.get("userName").value, "username", "user").pipe(debounceTime(500)).subscribe(
        data => {
          this.users = data["docs"];
          // console.log('users', this.users)
        }
      );
    }
  }

  focusOut() {
    this.users.length = 0;
  }

  setUser(user) {
    this.creditForm.patchValue({ user: user["id"] });
    this.creditForm.patchValue({ userName: user["username"] })
    this.users.length = 0;
    this.removeItemsFromCreditType(user.type.type)
  }

  onSubmit() {
    this.creditForm.removeControl('userName');
    this.creditForm.patchValue({ amount: parseInt(this.creditForm.get('amount').value) });
    this.creditService.defineCredit(this.creditForm.value).pipe().subscribe(
      data => {
        this.dataService.dataServiceItem.next({ type: "credit"});
        this.creditForm.addControl('userName', new FormControl('', Validators.required));
        this.creditForm.reset();
      }
    )
  }

  onChangeTransferType(value) {
    if (value === "bonus") {
      this.creditForm.patchValue({ gateway: undefined });
      this.creditForm.controls["gateway"].disable();
    } else {
      this.creditForm.controls["gateway"].enable();
    }
  }
}

export interface GiftItemInterface {
  image: string;
  name: string;
  credit: number;
  key: string;
}

export const GiftMap: GiftItemInterface[] = [
  {
    image: 'assets/img/su.png',
    name: 'Su',
    credit: 1,
    key: 'su',
  },
  {
    image: 'assets/img/cay.png',
    name:  'Çay',
    credit: 5,
    key: 'cay',
  },
  {
    image: 'assets/img/limonata.png',
    name: 'Limonata',
    credit: 10,
    key: 'limonata',
  },
  {
    image : 'assets/img/kahve.png',
    name: 'Kahve',
    credit: 15,
    key: 'kahve',
  },
  {
    image: 'assets/img/bira.png',
    name: 'Bira',
    credit: 25,
    key: 'bira',
  },
  {
    image: 'assets/img/sarap.png',
    name: 'Şarap',
    credit: 50,
    key: 'sarap',
  },
  {
    image: 'assets/img/viski.png',
    name: 'Viski',
    credit: 100,
    key: 'viski',
  },
  {
    image: 'assets/img/sampanya.png',
    name: 'Şampanya',
    credit: 250,
    key: 'sampanya',
  }
];
<section id="stream" class="noselect" style="height: 574px; overflow:hidden; width: 1250px; margin:0 auto;">
  <div class="content">
    <div class="stream-left">
      <div class="player-container">
        <div class="preview-player">
          <video #previewPlayer style="width: 100%; height: 100%; object-fit: contain;" autoplay></video>
        </div>
        <div class="darken"></div>
        <div class="video-tools">
          <div class="sound" [ngClass]="{'sound-muted': soundMuted }" (click)="toggleSound()"></div>
          <div class="vopts">
            <p class="viduser"><span class="vusername">{{streamer?.streamerName}}</span><span class="vidid"> ID. {{ streamer?.streamerId}}</span><br><span
                class="vidid">{{ streamer?.creditByPerMinute }}</span><span class="vidcinfo"> Kredi / Dakika</span></p>
          </div>
        </div>
        <div #chatArea class="chat-area">
          <div class="chat-message" *ngFor="let chat of chats; let messageIndex = index" @fadeIn>
            <b [ngClass]="{'ouser': chat?.owner?._id !== chatUser?._id, 'guser': chat?.gift}">{{chat?.owner?.username}}:
            </b>
            <span [ngClass] ="{'system-message': chat?.owner?.system }" *ngIf="!chat?.gift">{{chat.message}}</span>
            <span class="gift-span" [style.background-image]="'url(/'+getGift(chat.gift).image+')'"
              *ngIf="chat?.gift">{{getGift(chat.gift)?.name}} Gönderdi</span>
          </div>
        </div>
        <ngx-spinner name="player" bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="ball-pulse"
          [fullScreen]="false"></ngx-spinner>
      </div>
      <div class="chat-input">
        <textarea
          disabled
          spellcheck="false"
          [(ngModel)]="chatMessageBox"
        ></textarea>
      </div>
      <div class="chat-send-message drop-stream" style="right: 85px;">
        <a (click)="dropStream()"  class="grayround2 grdark">Yayını Düşür</a>
      </div>
      <div class="chat-send-message">
        <a (click)="send()" class="grayround2 grdark">Gönder</a>
      </div>
    </div>
    <div class="stream-right">
      <div class="public-head">
        <span>ODADAKİ ÜYELER</span><span class="right">{{ participants?.length }}</span>
      </div>
      <div class="public-ctn" style="height: 484px;">
        <div
          class="stchatpublic"
          *ngFor="let participant of participants"
          [ngClass]="{
            'premium-public': ownerTypeCheck(participant, userTypes().Premium),
            'vip-public': ownerTypeCheck(participant, userTypes().Vip),
            'public-status1': ownerTypeCheck(participant, userTypes().Normal),
            'public-status2': ownerTypeCheck(participant, userTypes().Noob),
            'public-status2': ownerTypeCheck(participant, userTypes().Guest)
          }"
        >
          <div
            class="userpicchat"
            *ngIf="ownerTypeCheck(participant, userTypes().Premium)"
          >
            <div class="participantPicRatio"
              [ngStyle]="{'background-image': bgProfilePhoto('User', participant.owner?.profile?.picture?.path)}">
            </div>
          </div>

          <span
            class="premium-public-name"
            *ngIf="
              ownerTypeCheck(participant, [
                userTypes().Premium,
                userTypes().Vip
              ])
            "
            >{{ participant.owner.username }}</span
          >

          <ng-container
            *ngIf="
              !ownerTypeCheck(participant, [
                userTypes().Premium,
                userTypes().Vip
              ])
            "
          >
            {{ participant.owner.username }}
          </ng-container>

          <span
            class="public-status"
            *ngIf="
              ownerTypeCheck(participant, [
                userTypes().Premium,
                userTypes().Vip
              ])
            "
          >
            {{ getUserType(participant.owner.type) }}
          </span>
          <div *ngIf="participant.ownerType !== 'Guest'" class="chatuoptions">
            <a (click)="drop(participant.owner)">ODADAN AT</a>
            <a (click)="block(participant.owner)">ENGELLE </a>
            <a (click)="warning(participant.owner)">CEZA VER</a>
          </div>
        </div>

        <!-- <div class="premium-public stchatpublic">
          <div class="userpicchat">
            <img src="assets/img/pp1.png"/>
          </div>
          <span class="premium-public-name">MEG</span>
          <span class="public-status">Premium</span>
          <div class="chatuoptions">
            <a href="#">MESAJ GÖNDER</a>
            <a href="#">TAKİP ET</a>
            <a href="#">ENGELLE</a>
          </div>
        </div>
        <div class="vip-public stchatpublic">
          <span class="premium-public-name">Nedo</span>
          <span class="public-status">VIP</span>
          <div class="chatuoptions">
            <a href="#">MESAJ GÖNDER</a><a href="#">TAKİP ET</a
          ><a href="#">ENGELLE</a>
          </div>
        </div>
        <div class="public-status1 stchatpublic">
          Umurcan45
          <div class="chatuoptions">
            <a href="#">MESAJ GÖNDER</a><a href="#">TAKİP ET</a
          ><a href="#">ENGELLE</a>
          </div>
        </div>

        <div class="public-status2 stchatpublic">
          Manyakadam
          <div class="chatuoptions">
            <a href="#">MESAJ GÖNDER</a><a href="#">TAKİP ET</a
          ><a href="#">ENGELLE</a>
          </div>
        </div>

        <div class="public-status2">
          Misafir-048204
        </div> -->
      </div>
      <div class="filters-info warnbuttons">
        <a (click)="chatMessageBox = 'Servisimiz üzerinden iletişim bilgisi paylaşmak üyelik sözleşmemize aykırıdır. Bu uyarıyı dikkate almamanız ve tekrarlamanız durumunda üyeliğiniz ceza alabilir, devamında ise süresiz kapanabilir.'">İLETİŞİM</a>
        <a (click)="chatMessageBox = 'Yayıncı ve üye arasında Birebir Görüşmelerde yapılan özel konuşmaların genel yayınlara taşınması topluluk kurallarımıza aykırıdır. Üyeliğinizin ceza almaması için lütfen yayıncı ile aranızdaki özel diyalogları genel yayınlarda paylaşmayınız.'">KİŞİSEL</a>
        <a (click)="chatMessageBox='Servisimiz üzerinde küfür / argo / hakaret içeren yazışmalar topluluk kurallarımıza aykırı olup, üyeliğinizin dondurulmasına ya da süresiz kapatılmasına sebep olabilir. Lütfen bu uyarıyı dikkate alınız.'">ARGO</a>
        <a (click)="chatMessageBox='Servisimiz üzerinde, başka yayın platformları ve ticari markaların reklam amaçlı adının kullanılması üyelik sözleşmemize aykırıdır. Hesabınızın ceza almaması için lütfen bu uyarıyı dikkate alınız.'">REKLAM</a>
      </div>
    </div>

    <div class="clr"></div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { PhotoService } from '../../services/photo.service';
import { StreamerService } from '../../services/streamer.service';
import { StreamerStateModel } from '../_model/streamer.state.model';

export class GetStreamers {
  static readonly type = "[StreamerState] GetStreamers";
  constructor() { }
}

@State<StreamerStateModel>({
  name: 'streamer',
})
@Injectable()
export class StreamerState {

  @Selector()
  static getWaitingApproveStreamerCount(state: StreamerStateModel) {
    return state.waitingStreamersCount;
  }

  @Selector()
  static getStreamer(state: StreamerStateModel) {
    return state.waitingStreamers;
  }

  constructor(private streamerService: StreamerService) { }

  @Action(GetStreamers)
  getStreamers({ patchState }: StateContext<StreamerStateModel>) {
    return this.streamerService.getAllStreamers({ status: 'waiting-approve' }).pipe(tap(
      (streamers: any) => {
        patchState({
          waitingStreamers: streamers.docs,
          waitingStreamersCount: streamers.totalDocs
        });
      }
    ));
  }
}

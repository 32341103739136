import { Directive, Input, TemplateRef, ViewContainerRef, ElementRef, OnInit, HostListener, Output, EventEmitter } from '@angular/core';
import { ManagerService } from '../../app/shared/services/manager.service';
import { AuthState } from 'src/app/shared/store/_state/auth.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthStateModel } from 'src/app/shared/store/_model/auth.state.model';

@Directive({
  selector: '[canClick]'
})

export class CanClickDirective implements OnInit {
  private currentUserPermissions;
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;
  @Select(AuthState.authUserPermissions) auth$: Observable<AuthStateModel>;
  constructor(
  ) {}

  ngOnInit() {
    this.auth$.subscribe((permissions: any) => {
      if (permissions && permissions.length) {
        this.currentUserPermissions = permissions;
      }
    });
  }

  @Output() canClick: EventEmitter<any> = new EventEmitter();
  @HostListener('click', ['$event'])
  onClick(e) {
    this.permissions.push(this.clickPermission)
    if(this.checkPermission()){
      this.canClick.next(e);
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @Input() clickPermission: string;

  private checkPermission() {
    let hasPermission = false;

    if (this.currentUserPermissions && this.currentUserPermissions.length) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.currentUserPermissions.find(
          (x) => x.toUpperCase() === checkPermission.toUpperCase(),
        );

        if (permissionFound) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
      }
    }

    return hasPermission;
  }
}

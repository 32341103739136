import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss']
})
export class AddCampaignComponent implements OnInit {

  name;
  constructor() {}
 
  ngOnInit() {

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StreamerService {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  postStreamer(obj) {
    return this.http.post(`${this.apiUrl}manager/streamer`, obj)
  }

  addNewNote(obj) {
    return this.http.post(`${this.apiUrl}manager/note`, obj)
  }

  getAllStreamers(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/streamer?${params}`);
  }

  getStreamerDetail(id: number) {
    return this.http.get(`${this.apiUrl}manager/streamer/${id}`);
  }

  addAdvance(id, obj) {
    return this.http.post(`${this.apiUrl}manager/streamer/${id}/give-advance`, obj)
  }

  addCommission(id, obj) {
    return this.http.post(`${this.apiUrl}manager/streamer/${id}/give-commission`, obj)
  }

  streamerStatistic(id) {
    return this.http.get(`${this.apiUrl}manager/streamer/${id}/statistic`)
  }

  getStreamerStories(obj) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(`${this.apiUrl}manager/story?${params}`);
  }

  getStreamerMessages(streamer: number,limit?) {
    let params = "";
    if (limit) {
      params += `&limit=${limit}`
    }
    return this.http.get(`${this.apiUrl}manager/streamer/${streamer}/messages?sort=createdAt:desc${params}`);
  }

  getStreamerNotes(streamer,limit?) {
    let params = `streamer=${streamer}`;
    if (limit) {
      params += `&limit=${limit}`
    }
    return this.http.get(`${this.apiUrl}manager/note?${params}&sort=createdAt:desc`);
  }

  updateStreamerLevel(data) {
    return this.http.patch(`${this.apiUrl}manager/streamer/${data.id}`, {level:data.level});
  }

  patchStreamerDetail(obj, id) {
    return this.http.patch(`${this.apiUrl}manager/streamer/${id}`, obj);
  }

  getEarnings(obj, userId) {
    let params = new URLSearchParams();
    for (let key in obj) {
      if (obj[key]) {
        params.append(key, obj[key]);
      }
    }
    return this.http.get(this.apiUrl + `manager/money/earnings?${params}`);

  }

  getRefEarnings(streamerId,limit?) {
    let params = `sort=createdAt:desc`;
    if (limit) {
      params += `&limit=${limit}`
    }
    return this.http.get(`${this.apiUrl}manager/streamer/${streamerId}/ref-earnings?${params}`);
  }

  getLogs(streamer) {
    return this.http.get(`${this.apiUrl}manager/log?streamer=${streamer}`);
  }

  getStreamerLogs(streamer, limit?) {
    let params = `streamer=${streamer}`;
    if (limit) {
      params += `&limit=${limit}`
    }
    return this.http.get(`${this.apiUrl}manager/log/stream?${params}&sort=createdAt:desc`);
  }

  getPayments(owner,limit?) {
    let params = `owner=${owner}`;
    if (limit) {
      params += `&limit=${limit}`
    }
    return this.http.get(`${this.apiUrl}manager/money/payments?${params}&sort=createdAt:desc`);
  }


  approvePhoto(streamerId) {
    return this.http.put(`${this.apiUrl}manager/streamer/${streamerId}/approve-picture`, {});
  }

  rejectPhoto(streamerId) {
    return this.http.put(`${this.apiUrl}manager/streamer/${streamerId}/reject-picture`, {});
  }

}

import { State, Selector, Action, StateContext } from "@ngxs/store";
import { UnapprovedPhotoStateModel } from '../_model/unapproved.-photo.state.model';
import { PhotoService } from '../../services/photo.service';
import { switchMapTo, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GetNumber } from '../_action/getNumber';
@State<UnapprovedPhotoStateModel>({
  name: 'unapprovedPhoto',
  defaults: {}
})
@Injectable()
export class UnapprovedState {
  @Selector()
  static getUnapprovedPhotoNumber(state: UnapprovedPhotoStateModel) {
    return state.number;
  }
  constructor(private photoService: PhotoService) { }

  @Action(GetNumber)
  getNumber({ patchState }: StateContext<UnapprovedPhotoStateModel>) {
    let number: number = 0;
    return this.photoService.getUnApprovedPicForUser({})
      .pipe(tap((data: any) => {
        number += data.totalDocs;
      }),
        switchMapTo(this.photoService.getUnApprovedPicForStreamer({})
          .pipe(tap((data: any) => {
            patchState({number:number + data.totalDocs});
          })))
      );
  }
}

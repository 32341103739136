export class SourceMapper {
  static sourceMap = {
    local: 'Cafecanli D.',
    global: 'Osocia',
    mobile : 'Cafecanli M.',
  }
  public static map(source: string): string {
    if(source in this.sourceMap) {
      return this.sourceMap[source];
    }
    return source;
  }
}

import { GetManager } from './../_action/get-manager';
import { ManagerService } from 'src/app/shared/services/manager.service';
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { AuthStateModel } from '../_model/auth.state.model';
import { AuthService } from '../../services/auth.service';
import { Login } from '../_action/login';
import { tap, switchMapTo, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Logout } from '../_action/logout';
@State<AuthStateModel>({
  name: 'auth',
  defaults: {}
})
@Injectable()
export class AuthState {
  @Selector()
  static token(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static authUserPermissions(state: AuthStateModel) {
    return state.permissions;
  }

  constructor(
    private authService: AuthService,
    private managerService: ManagerService
  ) { }

  @Action(Login)
  login(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { email, password }: Login
  ) {
    return this.authService.signin(email, password).pipe(
      tap(
        response => {
          patchState({
            accessToken: response.accessToken,
          });
        }
      ),
      switchMapTo(this.managerService.getManager().pipe(
        tap((response:any) => {
          patchState({
            email: response?.email,
            permissions: response?.permissions,
            status: response?.status,
            username: response?.username,
            _id: response?._id,
            lastLoginInfo: response?.lastLoginInfo,
          })
        })
      ))
    )
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authService.logout(state.accessToken).pipe(
      tap(() => {
        ctx.setState({
          accessToken: state.accessToken,
          email: null,
        });
      })
    )
  }

  @Action(GetManager)
  getManager(
    { patchState }: StateContext<AuthStateModel>,
  ) {
    return this.managerService.getManager().pipe(
      tap((response:any) => {
        patchState({
          email: response?.email,
          permissions: response?.permissions,
          status: response?.status,
          username: response?.username,
          _id: response?._id,
          lastLoginInfo: response?.lastLoginInfo,
        })
      })
    )
  }
}

export class DatetimeMapper {
  /**
   * Convert datetime milliseconds to hours
   * @param millis
   * return a string with 2 floating point
   */
  static fromMillisToHours(millis: number): string {
    const hours = (millis / 3600000);
    return hours.toFixed(2);
  }
}

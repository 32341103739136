import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PrivateLayoutComponent } from '../layout/private-layout/private-layout.component';
import { DataService } from '../services/data.service';
import { AuthState } from '../store/_state/auth.state';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  token: string;
  apiUrl = environment.prodUrl;

  constructor(
    private dataService: DataService,
    private store: Store,
    private layout: PrivateLayoutComponent,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (!request.url.includes('quickSearch')) {
      this.layout.showSpinner();
    }

    const headers = {} as any;
    const token = this.store.selectSnapshot(AuthState.token);

    if (!request.headers.has('Authorization') && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (
      !request.headers.has('Content-Type') &&
      request.headers.get('accept') !== 'application/json'
    ) {
      headers['Content-Type'] = 'application/json';
    }

    return next
      .handle(
        request.clone({
          setHeaders: headers,
        }),
      )
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            this.layout.endSpinner();
            if (event instanceof HttpResponse) {
              if (
                [200, 201].includes(event.status) &&
                (request.method === 'POST' ||
                  request.method === 'DELETE' ||
                  request.method === 'PATCH' ||
                  request.method === 'PUT')
              ) {
                if (
                  !(
                    request.method === 'PATCH' &&
                    request.url === `${this.apiUrl}manager/note`
                  )
                )
                  this.dataService.dataServiceItem.next({ type: 'successful' });
              }
            } else {
            }
          },
          (error) => {
            if ([400, 401, 403, 404, 409].includes(error.status)) {
              this.dataService.dataServiceItem.next({
                type: 'error',
                message: error?.error?.message,
              });
            }
          },
        ),
      );
  }
}

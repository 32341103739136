import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  apiUrl = environment.prodUrl;

  constructor(private http: HttpClient) { }

  getCredits() {
    return this.http.get(`${this.apiUrl}settings/credit`);
  }

  addCredit(obj) {
    return this.http.post(`${this.apiUrl}settings/credit`, obj);
  }

  updateCredit(obj) {
    return this.http.patch(`${this.apiUrl}settings/credit`, obj);
  }

  deleteCredit(id) {
    return this.http.delete(`${this.apiUrl}settings/credit/${id}`);
  }

  getPackages() {
    return this.http.get(`${this.apiUrl}settings/package`);
  }

  addPackage(obj) {
    return this.http.post(`${this.apiUrl}settings/package`, obj);
  }

  updatePackage(obj) {
    return this.http.patch(`${this.apiUrl}settings/package`, obj);
  }

  deletePackage(packageId) {
    return this.http.delete(`${this.apiUrl}settings/package/${packageId}`)
  }

  getSettings() {
    return this.http.get(`${this.apiUrl}settings`);
  }

  getStreamerSettings() {
    return this.http.get(`${this.apiUrl}settings/streamer-settings`);
  }

  updateSettings(obj) {
    return this.http.patch(`${this.apiUrl}settings`, obj);
  }

  getSettingLimits() {
    return this.http.get(`${this.apiUrl}settings/limits`);
  }

  getPublicCredits() {
    return this.http.get(`${this.apiUrl}settings/public/credits`);
  }

  getPublicPackages() {
    return this.http.get(`${this.apiUrl}settings/public/packages`);
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  apiUrl = environment.prodUrl+"manager/gift";
  
  constructor(private http: HttpClient) { }

  defineGift(requestModel): Observable<any> {
    return this.http.post(this.apiUrl, requestModel);
  }

  deleteGift(giftId){
    return this.http.delete(`${this.apiUrl}/${giftId}`);
  };


}

import { AuthStateModel } from './../../store/_model/auth.state.model';
import { AuthState } from './../../store/_state/auth.state';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClickService } from '../../services/click.service';
import { ManagerService } from '../../services/manager.service';
import { QuickSearchService } from '../../services/quick-search.service';
import { GetNumber } from '../../store/_action/getNumber';
import { GetWaitingPaymentNumber } from '../../store/_action/getWaitingNumber';
import { Logout } from '../../store/_action/logout';
import { GetStreamers, StreamerState } from '../../store/_state/streamer.state';
import { UnapprovedState } from '../../store/_state/unapproved-photo.state';
import { PaymentState } from '../../store/_state/waiting-payment.state';
import { GetManager } from '../../store/_action/get-manager';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  users = [];
  streamer = [];

  headerForm: FormGroup;
  typeNype;
  typeModal;

  @Select(UnapprovedState.getUnapprovedPhotoNumber) number$: Observable<number>;
  unApprovedPhoto;
  @Select(PaymentState.getWaitingNumber) waitingPaymentNumber$: Observable<number>;
  waitingPayment;
  @Select(StreamerState.getWaitingApproveStreamerCount) streamerCount$: Observable<number>;
  waitinStreamer;

  @Select(AuthState) auth$: Observable<AuthStateModel>;

  constructor(
    private fb: FormBuilder,
    private quickSearchService: QuickSearchService,
    private managerService: ManagerService,
    private router: Router,
    private store: Store,
    private clickService: ClickService
  ) { }



  ngOnInit(): void {
    this.auth$.subscribe((authData) => {
      if(!authData.lastLoginInfo) {
        this.store.dispatch(new GetManager());
      }
    })
    this.store.dispatch(new GetNumber());
    this.store.dispatch(new GetWaitingPaymentNumber());
    this.store.dispatch(new GetStreamers());

    this.number$.subscribe(number => this.unApprovedPhoto = number);
    this.waitingPaymentNumber$.subscribe(number => this.waitingPayment = number);
    this.streamerCount$.subscribe(number => this.waitinStreamer = number);

    this.headerForm = this.fb.group({
      userName: [],
      user: [],
      streamer: [],
      streamerName: [],
      membersearch: ['nick'],
      modelsearch: ['nick']
    })

    this.clickService.clickListener.subscribe(clickEvent => {
      this.users = [];
      this.streamer = [];
    })
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  onChanges(): void {
    // this.headerForm.get("userName").valueChanges.subscribe((val) => {
    //   if (val.length >= 3) {
    //     console.log(' this.typeNype', this.typeNype)
    //     this.quickSearchService.quickSearch(val, this.typeNype, "user").subscribe(
    //       data => {
    //         this.users = data["docs"];
    //       }
    //     );
    //   }
    // });
    // this.headerForm.get("streamerName").valueChanges.subscribe((val) => {
    //   if (val.length >= 3) {
    //     this.quickSearchService.quickSearch(val, this.typeModal, "streamer").subscribe(
    //       data => {
    //         this.streamer = data["docs"];
    //       }
    //     );
    //   }
    // });
  }

  change(type: string) {
    // console.log(type);
    if (type === 'userName') {
      if (this.headerForm.get("userName").value.length >= 3) {
        this.quickSearchService.quickSearch(this.headerForm.get("userName").value, this.typeNype, "user").subscribe(
          data => {

            // console.log(data);
            this.users = data["docs"];
          }
        );
      }
    } else {
      if (this.headerForm.get("streamerName").value.length >= 3) {
        this.quickSearchService.streamerQuickSearch(this.headerForm.get("streamerName").value).subscribe(
          data => {
            this.streamer = data["docs"];
          }
        );
      }
    }
  }


  setStreamer(streamer) {
    this.streamer.length = 0;
    this.router.navigate(['/streamer', streamer._id]);
  }

  setUser(user) {
    this.users.length = 0;
    this.router.navigate(['/members', user._id]);
  }


  onSubmit() { }

  onChange(e) {
    if (e.target.value === "nick") {
      this.typeNype = e.target.value;
    } else if (e.target.value === "eposta") {
      this.typeNype = e.target.value;
    }
  }

  onChangeModal(e) {
    if (e.target.value === "nick") {
      this.typeModal = e.target.value;
    } else if (e.target.value === 'isim') {
      this.typeModal = e.target.value;
    }
  }

  logout() {
    this.store.dispatch(new Logout());
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public dataServiceItem: Subject<{ type: string, message?: string}> = new Subject();
  
  constructor() {}
}
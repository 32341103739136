
import { StreamerStateModel } from './../store/_model/streamer.state.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StreamInfo } from '../store/_state/stream.state';
import { ChatInfoDataInterface } from '../constants';

export interface UserStatusInterface {
  ownerId: string;
  online: boolean;
}
export interface ManagerStatusInterface {
  ownerId: string;
  online: boolean;
  username: string;
}


@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // observables for chat
  chatParticipants: Observable<any> = new Observable<any>();
  chatMessage: Observable<any> = new Observable<any>();
  chatJoined: Observable<any> = new Observable<any>();
  chatInfo: Observable<ChatInfoDataInterface> = new Observable<ChatInfoDataInterface>();
  exception: Observable<object> = new Observable<object>();

  // user status
  userStatus: Observable<UserStatusInterface | UserStatusInterface[]> = new Observable<UserStatusInterface | UserStatusInterface[]>();
  managerStatus: Observable<ManagerStatusInterface | ManagerStatusInterface[]> = new Observable<ManagerStatusInterface | ManagerStatusInterface[]>();
  // streams
  streams: Observable<StreamInfo[]> = new Observable();
}

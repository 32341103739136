import { DataService } from 'src/app/shared/services/data.service';
import { SourceMapper } from 'src/app/shared/helper/source-mapper';
import { MembersService } from 'src/app/shared/services/members.service';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { environment } from 'src/environments/environment';
import { UserTypeMapper } from 'src/app/shared/utils/user-type.mapper';
import { UserTypeEnum } from '../../../utils/user-type.enum';
import { GiftItemInterface, GiftMap } from '../../../helper/gift.map';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Janus } from 'janus-gateway';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthStateModel } from '../../../store/_model/auth.state.model';
import { AuthState } from '../../../store/_state/auth.state';
import { Store } from '@ngxs/store';
import { StreamerStateModel } from '../../../store/_model/streamer.state.model';
import { Subject, from, merge, interval } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import { StreamService, StreamInterface, JanusEventTypeEnum } from '../../../services/stream.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { OnDestroy, OnInit, Component, ViewEncapsulation, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { takeUntil, tap, switchMap, switchMapTo, filter } from 'rxjs/operators';
import { AppType } from '../../../utils/app-type.util';
import { ChatInfoActionEnum, ChatInfoDataInterface } from 'src/app/shared/constants';
@Component({
  selector: 'private-stream',
  templateUrl: './private-stream.component.html',
  styleUrls: ['./private-stream.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(100%)' }))
      ])
    ]),
  ],
  // encapsulation: ViewEncapsulation.None,
})

export class PrivateStreamComponent implements OnInit, OnDestroy, AfterViewInit {
  streamer: StreamerStateModel;
  public chats: Array<object>;
  public soundMuted: boolean = false;
  public stream: StreamInterface;
  public user: any;
  public participants: Array<object>;
  public chatUser: any;
  public chatMessageBox: string;
  public gifts: GiftItemInterface[] = GiftMap;

  @ViewChild('chatArea') private chatArea: ElementRef;
  @ViewChild('previewPlayer') previewPlayer: ElementRef;
  @ViewChild('userPlayer') userPlayer: ElementRef;
  private _unsubscribeAll: Subject<any>;
  private _chatInfoUnsubscribe: Subject<any>;
  private _auth: AuthStateModel;

  appType = AppType;

  constructor(
    private streamService: StreamService,
    private sharedService: SharedService,
    private chatService: ChatService,
    private store: Store,
    private spinner: NgxSpinnerService,
    private ngxSmartModalService: NgxSmartModalService,
    private memberService: MembersService,
    private cd: ChangeDetectorRef,
    private dataService: DataService,
  ) {
    this._unsubscribeAll = new Subject();
    this._chatInfoUnsubscribe = new Subject();
  }

  ngOnInit() {
    this.chats = [];
    this._auth = this.store.selectSnapshot(AuthState);
    this.streamer = this.ngxSmartModalService.getModalData('streamModalPrivate');
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngAfterViewInit() {

    this.streamService.getStream(this.streamer.streamId)
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap(stream => {
          this.stream = stream;
        }),
        switchMap(stream => this.memberService.getUserDetail(stream?.user?.id)
          .pipe(
            tap(user => {
              this.user = user;
            })
          )),
        switchMap(_ => from(this.streamService.init(false))),
        switchMap(_ => from(this.streamService.attach(`manager/${this._auth._id}`, 'janusToken'))),
        switchMap(_ => from(this.streamService.join({
          id: `manager/${this.stream.streamer.id}`,
          room: this.streamer.streamId,
          roomToken: this.stream.accessToken,
          display: this._auth.username,
        }))),
      ).subscribe(_ => {
        this.subscribeNewChatMessages();
        this.subscribeJoined();
        this.subscribeInfo();
        this.chatService.join(this.streamer.streamId);
      }, (err: any) => {
        console.log(err);
        this.ngxSmartModalService.closeAll();
      });

    this.spinner.show('player');
    this.streamService.events.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(event => {
      switch (event.type) {
        case JanusEventTypeEnum.RemoteStream:
          const publisherType: 'streamer' | 'user' = event?.publisher?.id?.split('/')[0];
          if(publisherType === 'streamer'){
            this.spinner.hide('player');
            Janus.attachMediaStream(this.previewPlayer.nativeElement, event.stream);
            setTimeout(() => {
              try {
                this.previewPlayer.nativeElement.play();
              } catch (e) {
              }
            }, 100);
          } else {
            Janus.attachMediaStream(this.userPlayer.nativeElement, event.stream);
            setTimeout(() => {
              try {
                this.userPlayer.nativeElement.play();
              } catch (e) {
              }
            }, 100);
          }

          break;
        case JanusEventTypeEnum.PublisherLeaving:
          this.spinner.show('player');
          break;
        case JanusEventTypeEnum.PublisherJoined:
          this.spinner.hide('player');
          break;
        case JanusEventTypeEnum.RemoteStreamCleanUp:

          break;
      }
    });

    // interval(5000)
    // .pipe(
    //   takeUntil(this._unsubscribeAll),
    //   filter(_ => !!this?.stream?.user?.id),
    //   switchMapTo(this.memberService.getUserDetail(this.stream?.user?.id)
    //   .pipe(
    //     tap(user => {
    //       this.user = user;
    //     })
    //   )),
    // ).subscribe();
  }


  ngOnDestroy() {
    console.log("🚀 ~ file: private-stream.component.ts ~ line 180 ~ PrivateStreamComponent ~ ngOnDestroy ~ ngOnDestroy", 'ngOnDestroy')
    this.hangup();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private hangup() {
    console.log("🚀 ~ file: private-stream.component.ts ~ line 187 ~ PrivateStreamComponent ~ hangup ~ hangup", 'hangup')
    
    this.streamService.hangup();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.chatService.leave(this.streamer.streamId);
  }
  toggleSound() {
    this.soundMuted = !this.soundMuted;
    this.previewPlayer.nativeElement.muted = this.soundMuted;
  }

  getGift(key: string) {
    return this.gifts.find(g => g.key === key);
  }


  protected subscribeJoined() {
    this.sharedService.chatJoined
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
        this.chatUser = user;
      });
  }

  protected subscribeNewChatMessages() {
    this.sharedService.chatMessage
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(chat => {
        this.chats.push(chat);
        this.scrollToBottom();
      });
  }

  protected subscribeInfo() {
    this.sharedService.chatInfo
      .pipe(
        takeUntil(
          merge(this._unsubscribeAll, this._chatInfoUnsubscribe))
      )
      .subscribe((data: ChatInfoDataInterface) => {
        let message;
        console.log("GETTING DATA", data);
        switch (data.action) {
          case ChatInfoActionEnum.StreamerLeft:
            this.dataService.dataServiceItem.next({ type: 'successful', message: 'Yayın sona erdi'});
            break;
        }
        this._chatInfoUnsubscribe.next();
        this._chatInfoUnsubscribe.complete();
        this.ngxSmartModalService.close('streamModalPrivate');
      })
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.chatArea.nativeElement.scrollTop = this.chatArea.nativeElement.scrollHeight;
      } catch (err) { }
    }, 0);
  }

  send() {
    if (!this.chatUser?.canWrite) {
      return;
    }

    if (!this.chatMessageBox) {
      return;
    }
    this.chatService.sendChatMessage({
      roomId: this.streamer.streamId,
      message: this.chatMessageBox
    });
    this.chatMessageBox = '';
  }
  dropStream() {
    this.streamService.dropStream(this.stream.id).subscribe(()=>{})
  }
  block() {
    this.chatService.ban(this.stream.user.id);
  }

  drop() {
    this.chatService.drop(this.stream.user.id);
  }

  warning(){
    const modal = this.ngxSmartModalService.create('warningModal', WarningModalComponent, { closable: false, customClass: 'warning-dialog nsm-centered'});
    modal.setData({
      _id: this.stream?.user?.id,
      name: this.stream?.user?.username,
    })
    modal.open();
  }

  bgProfilePhoto(type, path) {
    if (typeof path === "undefined" || path === null) {
      if (type === 'Streamer') {
        return "url('./assets/img/Streamer.png')";
      } else if (type === 'User') {
        return "url('./assets/img/Member.png')";
      }
    }
    return `url('${environment.prodUrl}${path}')`;
  }

  ownerTypeCheck(participant, type) {
    if (Array.isArray(type)) {
      return type.indexOf(participant.owner.type) !== -1;
    }

    return participant.owner.type === type;
  }

  userTypes(): typeof UserTypeEnum {
    return UserTypeEnum;
  }


  getUserType() {
    return UserTypeMapper.map(this.stream?.user?.type);
  }

  // getSource(source: string) {
  //   return SourceMapper.map(source);
  // }
}

import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OnlineUserModel } from '../_model/onlineUser.state.model';

export class SetOnlineUser {
  static readonly type = '[Stream] Set Online User';
  constructor(public user: OnlineUserInterface) {}
}
export class DeleteOfflineUser {
  static readonly type = '[Stream] Delete Online User';
  constructor(public user: OnlineUserInterface) {}
}
export interface OnlineUserInterface {
  ownerId: string;
  online: boolean;
}

@State<OnlineUserModel>({
  name: 'onlineUserList',
  defaults: {
    onlineUserList: new Set(),
  },
})
@Injectable()
export class OnlineUserState {
  @Selector()
  static getOnlineUserList(state: OnlineUserModel) {
    return state.onlineUserList;
  }

  constructor() {}

  @Action(SetOnlineUser)
  setOnlineUser(ctx: StateContext<OnlineUserModel>, user : SetOnlineUser) {
    ctx.setState({
      onlineUserList: ctx.getState().onlineUserList.add(user.user.ownerId),
    });
  }
  @Action(DeleteOfflineUser)
  deleteOfflineUser(ctx: StateContext<OnlineUserModel>, user : SetOnlineUser) {
    ctx.getState().onlineUserList.delete(user.user.ownerId),
    ctx.setState({
      onlineUserList: ctx.getState().onlineUserList,
    });
  }
}

import { UserStateModel } from './../_model/user.state.model';
import { StreamService } from './../../services/stream.service';
import { StreamStateModel } from './../_model/stream.state.model';
import { StreamerStateModel } from './../_model/streamer.state.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ChatParticipantInterface } from '../../constants';

export class SetDevices {
  static readonly type = '[Stream] Set Devices';
  constructor(public videoDeviceId, public audioDeviceId) {}
}

export class SetLocalStream {
  static readonly type = '[Stream] Set Local Stream';
  constructor(public stream) {}
}

export class SetRemoteStream {
  static readonly type = '[Stream] Set Remote Stream';
  constructor(public stream) {
    console.log("SET REMOTE STREAM ACTION CREATED");
  }
}

export class PublisherJoined {
  static readonly type = '[Stream] Publisher joined';
  constructor() {}
}

export class PublisherLeaved {
  static readonly type = '[Stream] Publisher Leaved';
  constructor() {
    console.log("###PublisherLeaved");
  }
}

export class SetParticipants {
  static readonly type = '[Stream] Set Participants';
  constructor(public participants: number[]) {}
}



export class GetToken {
  static readonly type = '[Stream] Get Token';
  constructor() {}
}

export interface StreamInfo {
  streamer: StreamerStateModel;
  user: UserStateModel;
  type: 'public' | 'private';
  startedAt?: Date;
  participants?: ChatParticipantInterface[];
}

export class SetPublicStreamers {
  static readonly type = '[Stream] Set Public Streamers';
  constructor(public streams: StreamInfo[] ) {
  }
}

export class SetPrivateStreamers {
  static readonly type = '[Stream] Set Private Streamers';
  constructor(public streams: StreamInfo[] ) {
  }
}

export class SetFakeStreamers {
  static readonly type = '[Stream] Set Fake Streamers';
  constructor(public streams: StreamInfo[]) {
  }
}

export class HangupStream {
  static readonly type = '[Stream] Hangup Stream';
  constructor() {
  }
}


@State<StreamStateModel>({
  name: 'stream',
})
@Injectable()
export class StreamState {

  @Selector()
  static privateStreamers(state: StreamStateModel): StreamInfo[] {
    return state.privateStreamers;
  }

  @Selector()
  static publicStreamers(state: StreamStateModel): StreamInfo[] {
    return state.publicStreamers;
  }

  @Selector()
  static fakeStreamers(state: StreamStateModel): StreamInfo[] {
    return state.fakeStreamers;
  }

  constructor(private readonly streamService: StreamService) {}

  @Action(GetToken)
  getToken(
    { patchState }: StateContext<StreamStateModel>,
  ) {
    return this.streamService.getToken()
    .pipe(
      tap(response => {
        patchState({
          janusToken: response.token,
         })
      })
    )
  }


  @Action(SetPrivateStreamers)
  setPrivateStreamers(
    { patchState }: StateContext<StreamStateModel>,
    { streams }: SetPrivateStreamers
  ) {
    patchState({
      privateStreamers: streams,
    })
  }

  @Action(SetPublicStreamers)
  setPublicStreamers(
    { patchState }: StateContext<StreamStateModel>,
    { streams }: SetPublicStreamers
  ) {
    patchState({
      publicStreamers: streams,
    })
  }

  @Action(SetFakeStreamers)
  setFakeStreamers(
    { patchState }: StateContext<StreamStateModel>,
    { streams }: SetFakeStreamers,
  ) {
    patchState({
      fakeStreamers: streams
    })
  }
}
